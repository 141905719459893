
const config = {
    development: {
      backendUrl:'http://dev.chatcells.ai/api/backend/',
      chatbotUrl:'http://dev.chatcells.ai/api/',

    },
    production: {
      backendUrl: 'https://chatcells.ai/api/backend/',
      chatbotUrl:'https://chatcells.ai/api/',
    }
  };
  
export default config;
  