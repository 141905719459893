import React from "react";
import { GoogleGeminiEffectDemo } from "./Landing Page/AnimationComponent";
import { CardHoverEffectDemo } from "./Landing Page/Features";
import WelcomePage from "./Landing Page/WelcomePage";
// import FaQs from "./FaQs";
import Widgets from "./Landing Page/Widgets";
import Footer from "./Landing Page/Footer";
import FAQ from "./Landing Page/FAQ";

const Home = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-10 w-full">
      <div className="w-full">
        <WelcomePage />
      </div>
      <div className="bg-gradient-to-r self-center from-lime-300 via-lime-700 to-lime-300  shadow-lime-500/25 h-0.5 w-1/2 mt-20 shadow-xl  rounded-xl relative">
      </div>
      <div className="w-full hidden lg:block">
        <GoogleGeminiEffectDemo />
      </div>
      <div className="w-full">
        <CardHoverEffectDemo />
      </div>
      <div className="w-full">
        <FAQ />
      </div>
      <div className="w-full flex justify-center items-center px-5">
        <Widgets />
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Home;