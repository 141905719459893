import React from "react";
import slack from "../../Images/slack.png";
import whatsapp from "../../Images/whatsapp.png";
import wordpress from "../../Images/wordpress.png";
import shopify from "../../Images/shopify.png";
import messenger from "../../Images/messenger.png";
import analytics1 from "../../Images/Analytics/analytics1.png"
import analytics2 from "../../Images/Analytics/analytics2.png"
import analytics3 from "../../Images/Analytics/analytics3.png"
import analytics4 from "../../Images/Analytics/analytics5.png"
// import analytics5 from "../../Images/Analytics/analytics6.png"
// import { MdOutlineDoubleArrow } from "react-icons/md";
// import { IoRemoveOutline } from "react-icons/io5";
const Widgets = () => {
  const widgets = [
    {
      name: "Whatsapp",
      icon: whatsapp,
    },
    {
      name: "Wordpress",
      icon: wordpress,
    },
    {
      name: "Shopify",
      icon: shopify,
    },
    {
      name: "Slack",
      icon: slack,
    },
    {
      name: "Messenger",
      icon: messenger,
    },
  ];
  const Analytics =[
    {
      name: "Data1",
      image: analytics1,
    },
    {
      name: "Data2",
      image: analytics2,
    },
    {
      name: "Data3",
      image: analytics3,
    },
    {
      name: "Data4",
      image: analytics4,
    },
  ]
  return (
    <div className="flex lg:w-4/5 w-11/12 flex-col gap-5 justify-center my-12">
      <h2 className="text-3xl font-bold text-lime-700"> Upcoming Features </h2>
      <div className="flex items-center gap- w-full relative">
        <div className="w-0.5 rounded-lg h-[58rem] md:h-[65rem] lg:h-[55rem] xl:h-[45rem] bg-lime-800 "></div>
        <div className="flex flex-col gap-[26rem] md:gap-[32rem] lg:gap-[30.2rem] xl:gap-[18.7rem] absolute top-10 ">
          <div className="flex gap-2 items-center">
            <div className="w-5 rounded-lg h-[2px] bg-lime-800"></div>
            <h4 className="text-2xl font-semibold text-lime-700 mt-  items-center ">
              {" "}
              Integrations{" "}
            </h4>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-5 rounded-lg h-[2px] bg-lime-800"></div>
            <h4 className="text-2xl font-semibold text-lime-700 mt-  items-center ">
              {" "}
              Advanced Analytics{" "}
            </h4>
          </div>
        </div>
        <div className=" flex flex-col lg:gap-28 sm:gap-16 md:gap-36 gap-20 justify-center w-full absolute top-16 ml-7">
          <div className="mb-8">
            {/* <h4 className="text-2xl font-semibold text-lime-700 mt-5 flex gap-2 items-center ">
              {" "}
              Integrations{" "}
            </h4> */}
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10 sm:gap-4 lg:gap-20 lg:w-4/5  justify-center items-center mt-10 lg:ml-16">
              {widgets.map((widget, i) => (
                <div
                  key={i}
                  className="flex flex-col gap-2 justify-center items-center"
                >
                  <div className="bg-lime-700 rounded-3xl p-4">
                    <div className="bg-white rounded-3xl p-4 flex justify-center">
                      <img src={widget.icon} width={70} alt="slack" />
                    </div>
                  </div>
                  <h2 className="font-semibold text-lime-700">{widget.name}</h2>
                </div>
              ))}
            </div>
          </div>
          <div>
            {/* <h4 className="text-2xl font-semibold text-lime-700 mt-5 flex gap-2 items-center">
              {" "}
              Advanced Analytics{" "}
            </h4> */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-10 lg:gap-8 sm:gap-4 lg:w-4/5  justify-center items-center mt-10 sm:mt-0 lg:ml-16">
              {Analytics.map((widget, i) => (
                <div
                  key={i}
                  className="flex flex-col gap-2 justify-center items-center"
                >
                  <div className="">
                    <div className="bg-white rounded-3xl p-4 flex justify-center">
                      <img src={widget.image} width={200} alt="slack" />
                    </div>
                  </div>
                  {/* <h2 className="font-semibold text-lime-700">{widget.name}</h2> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widgets;