import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import { HiOutlinePaintBrush } from "react-icons/hi2";
import { HiOutlineUsers } from "react-icons/hi2";
// import { GoDatabase } from "react-icons/go";

const Customize_Options = ({ setSelectedSection }) => {
  const [nav, setNav] = useState("general");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathnameArray = location.pathname.split("/");
    const section = pathnameArray[pathnameArray.length - 1];
    setNav(section);
    setSelectedSection(section);
  }, [location.pathname, setSelectedSection]);

  const handleNavClick = (section) => {
    setNav(section);
    navigate(`/chatbot/dashboard/customize/${section}`);
    setSelectedSection(section);
  };
  return (
    <div className="flex justify-center items-center w-5/6 sm:w-[89%] lg:w-[85%] md:mt-0">
        <div className={` flex lg:flex-col mx-2 overflow-y-auto bg-lime-500/25 py-1 sm:py-2.5 rounded-xl border border-lime-500/25 w-full`}>
          <nav className={`bg-transparent lg:text-base`}>
            <ul className={`lg:mx-3 flex flex-1 lg:flex-col`}>
              <li>
                <ul className="mx-4 gap-3 md:gap-8  flex font-semibold text-sm">
                  <button
                    className={`flex flex-col sm:flex-row rounded-md ${
                      nav === "general" ? "bg-lime-700 text-white shadow-lg" : " text-lime-900"
                    } hover:bg-transparent hover:text-lime-900 py-1 gap- sm:gap-2 items-center border-2 border-transparent px-1.5  sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg text-xs sm:text-sm`}
                    onClick={() => handleNavClick("general")}
                  >
                    <IoSettingsOutline className={`text-base sm:text-lg md:text-xl`}/>
                    General
                  </button>
                  <button
                    className={`flex flex-col sm:flex-row rounded-md ${
                      nav === "model" ? "bg-lime-700 text-white shadow-lg" : " text-lime-900"
                    } hover:bg-transparent hover:text-lime-900  py-1 gap- sm:gap-2 items-center border-2 border-transparent px-1.5  sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg text-xs sm:text-sm`}
                    onClick={() => handleNavClick("model")}
                  >
                    <BsStars className={`text-base sm:text-lg md:text-xl`}/>
                    Model
                  </button>
                  <button
                    className={`flex flex-col sm:flex-row rounded-md ${
                      nav === "chatui"
                        ? "bg-lime-700 text-white shadow-lg" : " text-lime-900"
                      } hover:bg-transparent hover:text-lime-900  py-1 gap- sm:gap-2 items-center border-2 border-transparent px-1.5  sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg text-xs sm:text-sm `}
                    onClick={() => handleNavClick("chatui")}
                  >
                    <HiOutlinePaintBrush className={`text-base sm:text-lg md:text-xl`}/>
                    Chat UI
                  </button>
                  {/* <button
                    className={`flex rounded-md ${
                      nav === "datasources"
                        ? "bg-lime-700 text-white shadow-lg" : " text-lime-900"
                      } hover:bg-transparent hover:text-lime-900  py-1.5 gap-2 items-center border-2 border-transparent px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg text-sm`}
                    onClick={() => handleNavClick("datasources")}
                  >
                    <GoDatabase  className={`text-xl`}/>
                    Data Sources
                  </button> */}
                  <button
                    className={`flex flex-col sm:flex-row rounded-md ${
                      nav === "leadsui" ? "bg-lime-700 text-white shadow-lg" : " text-lime-900 "
                    } hover:bg-transparent hover:text-lime-900  py-1 gap- sm:gap-2 items-center border-2 border-transparent px-1.5  sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg text-xs sm:text-sm`}
                    onClick={() => handleNavClick("leadsui")}
                  >
                    <HiOutlineUsers className={`text-base sm:text-lg md:text-xl`}/>
                    Leads UI
                  </button>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
  );
};

export default Customize_Options;
