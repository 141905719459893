import React from "react";
import PropTypes from "prop-types";

const Input = ({
  label,
  name,
  value,
  type,
  handleOnChange,
  placeholder,
  className,
  minLength,
  required,
  disabled,
}) => {

  return (
    <div className={`flex flex-col gap-2`}>
      <label htmlFor={name} className="flex font-medium ">
        {label}
      </label>
      <input
        type={type}
        className={`border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none rounded-md text-sm ${className}`}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        minLength={minLength}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  minLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  value: "",
  className: "",
  minLength: 0,
  required: false,
  disabled: false,
};

export default Input;
