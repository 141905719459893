import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useDataSourcesState } from "../Context/DataSourcesContext";

const BotPersonality = ({ setOpenBotPersonality }) => {
  const {
    selectedPersonality,
    setSelectedPersonality,
    customPersonality,
    setCustomPersonality,
    personalities,
  } = useDataSourcesState();

  const [isManualModalOpen, setManualModalOpen] = useState(false);
  const [tempCustomPersonality, setTempCustomPersonality] = useState("");

  const handlePersonalitySelect = (personality) => {
    setCustomPersonality("");
    setSelectedPersonality(personality);
    setOpenBotPersonality(false);
  };

  const handleManualIconClick = () => {
    setSelectedPersonality(null);
    setManualModalOpen(true);
  };
  const handleModalClose = () => {
    setManualModalOpen(false);
    if (customPersonality.length === 0) {
      setSelectedPersonality(personalities[0]);
    }
  };

  const handleManualSubmit = () => {
    setCustomPersonality(tempCustomPersonality);
    if (customPersonality.length === 0) {
      setSelectedPersonality(personalities[0]);
    }
    setManualModalOpen(false);
    setOpenBotPersonality(false);
    setTempCustomPersonality("");
  };

  const closeModule = () => {
    setOpenBotPersonality(false);
  };

  return (
    <div className="flex flex-col flex-wrap justify-center items-center mt-8 relative bg-white w-4/5 rounded-md ">
      <button
        className="text-2xl text-red-500 absolute right-2 top-2 hover:opacity-60"
        onClick={closeModule}
      >
        <RxCrossCircled />
      </button>
      <div className="mt-10 mb-5 md:my-5">
        <h2 className="text-lg text-center md:text-2xl text-zinc-800 font-semibold">
          Choose an appropriate role for your chatbot
        </h2>
      </div>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex flex-wrap justify-center">
          {personalities.map((personality) => (
            <div
              key={personality.id}
              className={`p-4 m-4 cursor-pointer border border-lime-500/25 rounded-lg flex flex-col justify-center items-center hover:bg-lime-500/25 w-2/5 md:w-1/6  ${
                selectedPersonality?.id === personality.id
                  ? "bg-lime-500/25"
                  : ""
              }`}
              onClick={() => handlePersonalitySelect(personality)}
            >
              <span className="text-3xl">{personality.icon}</span>
              <p className="text-xs mt-3 text-zinc-600 text-center">
                {personality.name}
              </p>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-6  mx-2 w-11/12">
          <span className="w-1/3 mr-2 h-px bg-lime-500/25 "></span>
          <p className=" opacity-70 text-gray-800">OR</p>
          <span className=" w-1/3 ml-2 h-px bg-lime-500/25 "></span>
        </div>
        <h3 className="mt-8 text-zinc-700 text-lg font-semibold">
          Your Custom personality
        </h3>
        <div
          className={`p-2 my-5 cursor-pointer border rounded-lg flex flex-col justify-center items-center w-[180px] hover:bg-lime-500/25  ${
            customPersonality ? " bg-lime-500/25 " : " bg-white"
          } `}
          onClick={handleManualIconClick}
        >
          <span className="text-3xl">✏️</span>
          <p className="text-xs mt-3 text-zinc-600">
            {customPersonality ? customPersonality : "Custom"}{" "}
          </p>
        </div>
      </div>
      {/* Modal for Manual Personality */}
      {isManualModalOpen && (
        <>
          <div className="w-full h-full fixed top-0 left-0 bg-black opacity-80 z-10"></div>
          <div className="mt-8 bg-white opacity-100 rounded-xl shadow-2xl lg:w-1/3 absolute top-28 z-50 transform translate-y-4 transition duration-500 ease-in-out border border-lime-500/25">
            <div className="p-4 mt-4 flex flex-col justify-center items-center">
              <h2 className="text-xl font-bold mb-8 text-center text-zinc-700">
                Enter Custom Personality
              </h2>
              <div className="flex gap-4 w-full justify-center items-center mb-5">
                <input
                  type="text"
                  value={tempCustomPersonality}
                  onChange={(e) => setTempCustomPersonality(e.target.value)}
                  placeholder="Type your custom personality..."
                  className="border border-gray-300 text-sm p-2 w-1/2 rounded-lg focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-600"
                />
                <button
                  onClick={handleManualSubmit}
                  className="py-1.5 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out "
                >
                  Save
                </button>
              </div>
              <button
                className="absolute top-2 right-4 text-red-400 hover:opacity-70"
                onClick={handleModalClose}
              >
                {" "}
                <i className="fa-regular fa-circle-xmark"></i>
              </button>
            </div>
          </div>
        </>
      )}
       <button
        className="text-lg md:hidden bg-red-400 py-1 px-4 rounded-lg hover:bg-transparent hover:text-red-600 hover:border-red-500 border text-black my-2 hover:opacity-60 font-semibold"
        onClick={closeModule}
      >
        Close
      </button>
    </div>
  );
};

export default BotPersonality;
