import React, { useEffect, useState } from "react";
import DataInput from "../DataInput";
import { useSharedState } from "../../Context/SharedStateContext";
import { useDataSourcesState } from "../../Context/DataSourcesContext";

const DataSources = () => {
  const { chatbotDetails, pagesState } = useSharedState();
  const { setChatbotData, resetState } = useDataSourcesState();
  const [inCustomize, setinCustomize] = useState(true);
  useEffect(() => {
    resetState();
    console.log("resetting state");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setChatbotData((prevState) => ({
      ...prevState,
      AddedLinkList: chatbotDetails?.crawledUrls,
      Addedlinkchars: chatbotDetails.crawledUrls.reduce(
        (totalcount, link) => totalcount + link.count,
        0
      ),
      AddedText: chatbotDetails.textContent,
      Addedtextchars: chatbotDetails.textContent.length,
      AddedFiles: chatbotDetails.fileNames,
      Addedfilechars: chatbotDetails.fileNames.reduce(
        (totalcount, file) => totalcount + file.count,
        0
      ),
      AddedMediaFiles: chatbotDetails.mediaFileNames,
      AddedMediaFilechars: chatbotDetails.mediaFileNames.reduce(
        (totalcount, file) => totalcount + file.count,
        0
      ),
      AddedqaList: [],
      AddedqaChars: 0,
    }));
    console.log(chatbotDetails);
  }, [setChatbotData, chatbotDetails]);
  if (Object.values(pagesState).some((value) => value === true)) {
    return <div className="min-h-[80vh]"></div>;
  }
  return (
    <div className="w-full flex justify-center">
      <DataInput inCustomize={inCustomize} setinCustomize={setinCustomize} />
    </div>
  );
};

export default DataSources;
