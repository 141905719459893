import React, { useState,useRef,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { LuRefreshCcw } from "react-icons/lu";
import { DeepChat } from 'deep-chat-react';
import "./Chatbot.css";
import { useSharedState } from "../../Context/SharedStateContext";
import { toast } from "../../Components/UI/NotificationManager";
import config from '../../config'
const Chatbot = ({
  chatinterface,
  localChatUISettings,
}) => {
  const { selectedChatbot } = useSharedState();
  const chatElementRef = useRef(null);
  const [initialMessages, setinitialMessages] = useState();
  const conversationId = useRef('');
  const [messagesInfo,setmessagesInfo]=useState({
    messagesAllowed:'',
    messagesUsed:''
  })
  const dyanamicChatbotUrl=config[process.env.REACT_APP_Running_Mode].chatbotUrl;

  useEffect(() => {
    if (chatElementRef.current) {
      setinitialMessages([
        ...localChatUISettings['textArray']?.filter(message => message.text.trim() !== '').map((message) => (message.text.trim() !== '' && {
          text: message.text,
          role: 'ai'
        })),
        localChatUISettings['suggestedMessagearray']
          .filter(message => message.trim() !== '') // Filter out empty strings
          .length > 0 ? {
            html: `
            <div class="" style="display:flex;flex-direction:row;position:absolute; z-index:10;white-space:nowrap;padding-left:2%;left:0px;min-width:100px;height:50px;padding-right:20px;right:0px;bottom:45px;width:100%;overflow-x:auto">
              ${localChatUISettings['suggestedMessagearray'].map((message, ind) => (message &&
                `<button class="deep-chat-button deep-chat-suggestion-button" style="color:${(localChatUISettings.colorPicker !== undefined && localChatUISettings.colorPicker !== '') ? localChatUISettings.colorPicker : '#3F6212'};border: 2px solid ${(localChatUISettings.colorPicker !== undefined && localChatUISettings.colorPicker !== '') ? localChatUISettings.colorPicker : '#3F6212'};  border-radius:10px;display:flex;align-items:center; padding:10px;margin-top: 5px;text-decoration: underline; margin-left:auto;margin-right:10px;margin-bottom:5px;background-color:#F3F4F6"
                  ${!chatinterface ? 'disabled' : ''}>${message}</button>`
              )).join('')}
            </div>
          `,
          role: 'ai',
        } : null
      ]?.filter(message => message !== null));
      chatElementRef.current.request = {
        handler: async (body, signals) => {
          const userQuery = body.messages[0].text;
          let data;
          let chatbotIdParts = selectedChatbot?.chatbotId.split("_")
        let requestBody = {
          userQuery,
          source: 'Chatcells',
          chatbotId:
            chatbotIdParts.length > 1
              ? `${chatbotIdParts[1]}`
              : `${selectedChatbot?.chatbotId}`,
              internal:'True',
        };
        if (conversationId.current.conversationId) {
                  requestBody.conversationId = conversationId.current.conversationId;
                }

          try {
            const response = await fetch(`${dyanamicChatbotUrl}query-chatbot/`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(requestBody),
            });
            data = await response.json();
            conversationId.current=data;
            if(data.Error)
            {
              toast.error(data.Error)
            }
              else
            signals.onResponse({ text: data.response });
          } catch (error) {
            // Use error.message for better logging
            signals.onResponse({ error: error.message });
          } finally {
            // Update initialMessages in the finally block
            if (data && data.response) {
              setinitialMessages(prevMessages => [
                ...prevMessages,
                { text: userQuery, role: 'user' },
                { text: data.response, role: 'ai' }
              ]);
              
              setmessagesInfo({
              messagesAllowed:data.maxMessages,
                messagesUsed:data.messagesUsed
            })
            }
            else {

              setinitialMessages(prevMessages => [
                ...prevMessages,
                { text: userQuery, role: 'user' },
                { text: "Failed to fetch the data", role: 'ai' }
              ]);
            }
          }
        },
      };
    }
  }, [chatElementRef,localChatUISettings,selectedChatbot?.chatbotId,dyanamicChatbotUrl,chatinterface]);


  const reload = () => {
    conversationId.current='';
    setinitialMessages([
      ...localChatUISettings['textArray']?.filter(message => message.text.trim() !== '').map((message) => (message.text.trim() !== '' && {
        text: message.text,
        role: 'ai'
      })),
      localChatUISettings['suggestedMessagearray']
        .filter(message => message.trim() !== '') // Filter out empty strings
        .length > 0 ? {
        html: `
          <div class="" style="display:flex;flex-direction:row;position:absolute; z-index:10;white-space:nowrap;padding-left:2%;left:0px;min-width:100px;height:50px;padding-right:20px;right:0px;bottom:45px;width:100%;overflow-x:auto">
          ${localChatUISettings['suggestedMessagearray'].map((message, ind) => (message &&
            `<button class="deep-chat-button deep-chat-suggestion-button" style="color:${(localChatUISettings.colorPicker !== undefined && localChatUISettings.colorPicker !== '') ? localChatUISettings.colorPicker : '#3F6212'};border: 2px solid ${(localChatUISettings.colorPicker !== undefined && localChatUISettings.colorPicker !== '') ? localChatUISettings.colorPicker : '#3F6212'}; border-radius:10px; padding:10px;margin-top: 5px;text-decoration: underline; display:flex;align-items:center; margin-left:auto;margin-right:10px;margin-bottom:5px;background-color:#F3F4F6" ${!chatinterface ? 'disabled' : ''}>${message}</button>`
          )).join('')}
          
          </div>`,
        role: 'ai'
      } : null
    ]?.filter(message =>( message !== null)));
  };
 
  return (
    <>
      <div
        className={` flex flex-col w-[90%] gap-3 `}
      >
        <div
          className={`flex flex-col ${
            localChatUISettings["Themes"] === "dark" ? "bg-black" : "bg-white"
          } gap-4 rounded-[0.9rem]`}
        >
          <div className=" rounded-[0.8rem]  h-[530px] flex flex-col shadow-2xl ">
            <div className={`refresh w-[100%] flex items-center rounded-t-[0.8rem] mx-auto h-[9%] border-b border-solid border-lime-500/25   `} style={{ backgroundColor: localChatUISettings.TitleBarColor? localChatUISettings.TitleBarColor : 'white' }}>
              <div className="profile flex gap-1 items-center p-1">
                {localChatUISettings["imagePreview"] && (
                  <div className="p-1   rounded-[50px] flex justify-center items-center ">
                    <img
                      src={localChatUISettings["imagePreview"]}
                      alt="profile"
                      className="w-[34px] h-[34px] bg-white rounded-full "
                    />
                  </div>
                )}
                {localChatUISettings["name"] && (
                  <span
                    className={`font-medium ml-1.5 text-gray-800 opacity-90 ${
                      localChatUISettings["Themes"] === ("dark" || "Dark")
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    {localChatUISettings["name"]}
                  </span>
                )}
              </div>
              <div className="icon_div flex  mt-auto mr-[10px] ml-auto mb-auto gap-[15px]">
                <LuRefreshCcw
                  className={`icon cursor-pointer text-lg   ${localChatUISettings["Themes"] === "dark" ? "text-white" : "text-black"}`}
                  onClick={chatinterface ? reload : null}
                />
                {!chatinterface && (
                  <RxCross2 className={`icon cursor-pointer text-lg   ${localChatUISettings["Themes"] === "dark" ? "text-white" : "text-black"}`} />
                )}
              </div>
            </div>
            <div className="Body flex flex-col justify-center items-center h-full m-[8px] ">
            <DeepChat
                style={{ width: '100%', height: '465px', border: 'none', backgroundColor: localChatUISettings.Themes === 'light' ? 'white' : 'black' }}
                textInput={{
                  styles: {
                    text: { color: 'black' },
                    container: { width: '96%', minHeight: '25px', backgroundColor: '#f5f9ff', borderRadius: '20px', paddingTop: '4px', paddingBottom: '4px', marginBottom: '2px' , marginTop:'50px',cursor:!chatinterface?'not-allowed':''},
                    focus: { border: '1px solid #3f6212' }
                  },
                  disabled:!chatinterface? true:false
                  ,
                  placeholder: { text: (localChatUISettings.placeholder !== undefined && localChatUISettings.placeholder!=='') ? localChatUISettings.placeholder : ' ', style: { color: '#4459a4' } },
                }}
                submitButtonStyles={{
                  submit: {
                    container: {
                      default: {
                        marginTop: "8px",
                        marginRight: "8px",
                      }
                    },
                    svg: {
                      styles: {
                        default: {
                          marginTop: "4px",
                          width: "25px", 
                          height: "25px", 
                          color: '#3f6212',
                          transform: "rotate(45deg)"
                        }
                      }
                    }
                  }
                }}
                messageStyles={{
                  html: {shared: {bubble: {backgroundColor: 'transparent', paddingLeft: '4px',paddingBottom:'6 px',paddingTop:'4px',width:'90%',lineHeight: '1.5', 
                        wordSpacing: '1px', textDecoration: 'underline',}} },
                        loading: {
                    bubble: {
                      color: 'white',
                      fontSize: '16px',
                      padding:'17px',
                      backgroundColor:'#F3F4F6'
                    },
                    error: {
                      bubble: { backgroundColor: "#f98e00", color: "white", fontSize: "15px" }
                    }
                  },
                 
                  default: {
                    ai: {
                      bubble: {
                        maxWidth: "90%",
                        lineHeight: '1.5', 
                        wordSpacing: '1px',
                         backgroundColor:'#F3F4F6',
                         padding:'10px'
                      }
                    },
                    user: {
                      bubble: {
                        backgroundColor: (localChatUISettings.colorPicker!==undefined && localChatUISettings.colorPicker!=='')? localChatUISettings.colorPicker :'rgb(132 204 22 / 0.25)' ,
                        maxWidth: "90%" ,
                        lineHeight: '1.5', 
                        wordSpacing: '1px',
                        color: '#3F6212',
                         padding:'10px'

                      }
                    }
                  }
                }}

                initialMessages={initialMessages}

                ref={chatElementRef}
              />

            </div>

            
          </div>
        </div>
        {!chatinterface ? (
          localChatUISettings["iconImage"] &&
          <div>
            <img
              src={localChatUISettings["iconImage"]}
              alt="chatlogo"
              className={` ${
                localChatUISettings["bubblePosition"] === "Right"
                  ? "ml-auto"
                  : "mr-auto"
              } mt-[-8px] w-[40px] h-[40px] rounded-full font-[60px] `}
            />
          </div>
        ):messagesInfo.messagesAllowed?(<div className=" flex m-2 justify-center items-center ">
        <div className="flex ">
          <span className="text-gray-700 text-sm font-medium">{messagesInfo.messagesUsed}/</span>
          <span className=" text-gray-700 text-sm font-medium">{messagesInfo.messagesAllowed}</span>


        </div>

        </div>):''}
      </div>
    </>
  );
};

export default Chatbot;