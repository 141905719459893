import React, { useEffect, useState } from "react";
import chatBotPng from "../Images/ChatbogPng.png";
import { useSharedState } from "../Context/SharedStateContext";
import { useNavigate } from "react-router-dom";
import SkeletonGetChatbots from "../Components/LoadingComponents/SkeletonGetChatbots";
import { useDataSourcesState } from "../Context/DataSourcesContext";

const GetChatbots = () => {
  const navigate = useNavigate();
  const { Chatbots, getChatbots, loadingChatbots } = useSharedState();
  const { resetState} = useDataSourcesState();
const {setSelectedChatbot} = useSharedState()
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      // If the ID is not present, redirect to login page
      navigate("/auth/login");
    } else {
      // If the ID is present, fetch chatbots
      getChatbots(user);
    }
    // eslint-disable-next-line
  }, []);

  const [hoveredChatbot, setHoveredChatbot] = useState(null);
  const handleOpenChatBot = (chatbot) => {
    setSelectedChatbot(chatbot);
    navigate(`/chatbot/dashboard/info`);
  };

  const handleCreateNew=()=>{
    resetState()
    navigate('/data-input')
  }

  return (
    <div className="mt-24 lg:mt-36 flex justify-center items-center w-full">
      <div className="flex flex-col justify-center items-center gap-12 lg:w-5/6">
        <div className="flex justify-between items-center w-11/12 lg:w-4/5 bg-lime-500/25 py-1.5 px-4 rounded-md border border-lime-500/25">
          <h4 className="text-2xl text-lime-800 font-bold text-center ">
            Chatbots
          </h4>
          <button onClick={handleCreateNew} className="py-1.5 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out text-sm">
            Create New
          </button>
        </div>
        {loadingChatbots ? (
          <SkeletonGetChatbots/>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 relative py-4  w-11/12 lg:w-4/5">
            <div
              className={`w-full h-full absolute top-0 left-0 z-10 rounded-md ${
                hoveredChatbot !== null ? " bg-gray-50 " : " bg-transparent "
              } opacity-100`}
            ></div>
            {Chatbots.map((chatBot, index) => (
              <div
                className={`flex justify-center rounded-md relative  transition-opacity duration-300 ease-in-out `}
                key={index}
              >
                <div
                  className={`flex flex-col gap-4 border-lime-500/25 border p-4 rounded-md cursor-pointer relative z-30 w-full ${
                    hoveredChatbot === index
                      ? " border-lime-700 "
                      : " border-lime-500/25 "
                  } ${hoveredChatbot === index ? "shadow-xl bg-white" : ""} ${
                    hoveredChatbot === index || hoveredChatbot === null
                      ? " opacity-100 "
                      : " opacity-20 "
                  }`}
                  onMouseEnter={() => setHoveredChatbot(index)}
                  onMouseLeave={() => setHoveredChatbot(null)}
                  onClick={() => handleOpenChatBot(chatBot)}
                >
                  <div className="bg-lime-500/25 text-lime-700 w-full text-center p-4 rounded-md flex justify-center items-center relative">
                    <img
                      width={120}
                      src={chatBotPng}
                      alt=""
                      className={` ${
                        hoveredChatbot === index || hoveredChatbot === null
                          ? " opacity-100 "
                          : " opacity-10 "
                      }`}
                    />
                  </div>
                  <p className="font-semibold inline-flex text-center text-lime-700 flex-wrap w-full">
                  <span className="max-w-full break-all text-center">{chatBot.chatbotName}</span>
                  </p>
                </div>
                <div
                  className={`absolute top-0 z-30 ${
                    index % 4 === 3 ? " right-full items-end " : " left-full "
                  } ml-1 hidden lg:flex flex-col gap-3 mt-0  py-4 px-2 rounded-md ${
                    hoveredChatbot === index ? "opacity-100  " : "opacity-0"
                  } transition-opacity duration-300 ease-in-out`}
                >
                  <span
                    className={`bg-white text-lime-700 font-semibold border border-lime-700 px-4 py-2 rounded-md flex items-center w-64 text-sm gap-1.5`}
                  >
                    <span
                      className={`${index % 4 === 3 ? "ml-auto" : "  "} `}
                    >
                      Created at{" "}
                    </span>
                    <span className="text-sm ">{chatBot.createdDate}</span>
                  </span>
                  <span
                    className={`bg-white text-lime-700 font-semibold border border-lime-700 px-4 py-2 rounded-md w-72 items-center flex  gap-1.5 text-sm `}
                  >
                    <span
                      className={`${index % 4 === 3 ? " ml-auto " : "  "} `}
                    >
                      Last Trained at{" "}
                    </span>
                    <span className="text-sm ">{chatBot.lastModifiedDate}</span>
                  </span>
                  <span
                    className={`bg-white text-lime-700 font-semibold border border-lime-700 px-4 py-2 rounded-md w-36 text-sm flex items-center gap-1.5`}
                  >
                    <span
                      className={`${index % 4 === 3 ? " ml-auto " : "  "} `}
                    >
                      Visibility:{" "}
                    </span>
                    <span className="text-sm ">{chatBot.visibility}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GetChatbots;
