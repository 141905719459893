import React, { useEffect, useState } from "react";

const FAQ = () => {

  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Checking  Safari browser because some features safari does not support
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(isSafariBrowser);
  }, []);
  return (
    <div className={`lg:w-11/12 lg:mx-auto lg:px-10 ${isSafari ? 'xl:mt-72 lg:mt-48' : ''}`}>
      <div className="bg-white p-4 lg:px-10 rounded-lg shadow-xl py-8 mt-12">
        <h4 className="text-3xl font-bold text-lime-700 tracking-widest lg:ml-12">
          FAQs
        </h4>
        <p className=" text-lime-600 text-lg font-semibold py-4 pr-4 mb-5 lg:ml-12">
          Here are some of the frequently asked questions
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4">
          <div className="flex space-x-8 mt-8">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                Who can use Chatcells?
              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base">
                Any business can integrate a ChatGPT powered chatbot to their website with a few clicks and leverage all the benefits
              </p>
            </div>
          </div>

          <div className="flex space-x-8 mt-8">
            <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                What type of content can be used for training?

              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base">
                You can train the chatbot with your website content, pdfs, docx files, text, audio and video content in a few clicks.

              </p>
            </div>
          </div>

          <div className="flex space-x-8 mt-8">
            <div>
            
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                Can I use my website content for training?
              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base">
                Yes - absolutely! You can train the chatbot with your website content in one-click. Just point to your URL, fetch all the links and click 'Train'.
              </p>
            </div>
          </div>

          <div className="flex space-x-8 mt-8">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                Is there a Free Trial?
              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base">
                Yes! We offer a free trial. You can create a chatbot for free that queries your content with a limit of 30 message tokens but with all the features to make sure you love the product.
              </p>

            </div>
          </div>

          <div className="flex space-x-8 mt-8">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                Can I use live chat on multiple websites?
              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base ">
                Yes - absolutely! Each of our plans supports a tiered number of websites. So just pick a plan that fits your needs.
              </p>
            </div>
          </div>

          <div className="flex space-x-8 mt-8">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-lime-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
                ></path>
              </svg>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-700">
                Do I have to sign a contract?
              </h4>
              <p className="text-gray-600 my-2 text-sm md:text-base">
                No. There are no contracts and you can cancel your subscription at any time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
