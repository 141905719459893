import React, { useState } from "react";
import { useDataSourcesState } from "../../Context/DataSourcesContext";

const QASource = () => {
  const {SourceData, setSourceData} = useDataSourcesState();
  const {  qaList } = SourceData;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const handleAddComponent = () => {
    if (question.trim() !== "" && answer.trim() !== "") {
      const newQaComponent = { question, answer };
  
      // Use setSourceData instead of setqaChars
      setSourceData((prevSourceData) => ({
        ...prevSourceData,
        qaChars: prevSourceData.qaChars + question.length + answer.length,
        qaList: [...prevSourceData.qaList, newQaComponent],
      }));
  
      setQuestion("");
      setAnswer("");
    }
  };
  
  const handleRemoveComponent = (index) => {
    // Subtract the char count of the removed component
    const removedComponent = qaList[index];
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      qaChars:
        prevSourceData.qaChars -
        removedComponent.question.length -
        removedComponent.answer.length,
      qaList: prevSourceData.qaList.filter((_, i) => i !== index),
    }));
  };
  

  return (
    <div className="p-2">
      <div className="my-4 mx-4 ">
        <div className="border border-lime-500/25 p-2 rounded-lg shadow-md">
          <div className="flex flex-col">
            <label className="text-sm text-gray-600 my-1">Question</label>
            <textarea
              rows={3}
              value={question}
              placeholder="Enter your question"
              required
              onChange={(e) => setQuestion(e.target.value)}
              className="border border-lime-500/25 p-2 rounded-md text-sm text-gray-700 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
            ></textarea>
          </div>
          <div className="flex flex-col my-2">
            <label className="text-sm text-gray-600 my-1">Answer</label>
            <textarea
              rows={5}
              value={answer}
              placeholder="Enter answer"
              required
              onChange={(e) => setAnswer(e.target.value)}
              className="border border-lime-500/25 p-2 rounded-md text-sm text-gray-700 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
            ></textarea>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 mt-3">
          <button
            className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out text-sm"
            onClick={handleAddComponent}
          >
            Add
          </button>
        </div>
        {qaList.length > 0 && (
          <div>
            <h2 className="text-center text-gray-700 my-1">
              Q&A Components ({qaList.length})
            </h2>
            <div className="border border-lime-500/25 shadow-sm rounded-lg mt-2">
              <ul>
                {qaList.map((component, index) => (
                  <li
                    key={index}
                    className="my-4 mx-4 border border-lime-500/25 p-3 rounded-lg relative"
                  >
                    <div className="flex flex-col">
                      <label className="my-1 text-sm text-gray-600">
                        Question {index + 1}:
                      </label>
                      <textarea
                        rows={3}
                        value={component.question}
                        className="border border-lime-500/25 p-2 rounded-md text-sm text-gray-600 bg-gray-100 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                        readOnly
                      ></textarea>
                    </div>
                    <div className="flex flex-col my-2">
                      <label className="my-1 text-sm text-gray-600">
                        Answer:
                      </label>
                      <textarea
                        rows={5}
                        value={component.answer}
                        className="border border-lime-500/25 p-2 rounded-md text-sm text-gray-600 bg-gray-100 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                        readOnly
                      ></textarea>
                    </div>
                    <button
                      className="absolute top-1 right-2 text-gray-500 hover:opacity-60"
                      onClick={() => handleRemoveComponent(index)}
                    >
                      <i className="fa-regular fa-circle-xmark"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default QASource;
