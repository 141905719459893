import React, { useEffect, useState,useRef} from "react";
import DateRangePicker from "../../Components/UI/DateRangePicker";
import { TiArrowSortedDown } from "react-icons/ti";
import { RiDownload2Fill } from "react-icons/ri";
import SkeletonLogs from "../../Components/LoadingComponents/SkeletonLogs";
import { DeepChat } from 'deep-chat-react';
import { useSharedState } from "../../Context/SharedStateContext";
const Logs = () => {
  const {
    getConversations,
    Conversations,
    loadingConversations,
    pagesState
  } = useSharedState();
 
  const [selectedSource, setSelectedSource] = useState([
    "API",
    "Chatcells",
    "Widget or Iframe",
  ]); // All sources selected by default
  const [isOpen, setIsOpen] = useState(false);
  const [SourceisOpen, setSourceIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedConvo, setSelectedConvo] = useState(0);
  const [logMessages,setlogMessages]=useState([]);
  const headerRef = useRef(null);
  useEffect(() => {
    // Calculate the start date (day before yesterday) and end date (today)
    const today = new Date();
    const dayBeforeYesterday = new Date(today);
    dayBeforeYesterday.setDate(today.getDate() - 2);
    const startDateString = dayBeforeYesterday.toISOString().split("T")[0];
    const endDateString = today.toISOString().split("T")[0];
    // Set the start and end dates
    setStartDate(startDateString);
    setEndDate(endDateString);
    // eslint-disable-next-line
  }, [selectedSource]);

  useEffect(() => {
    // Fetch conversations whenever selectedSource, startDate, or endDate change
    if (selectedSource && startDate && endDate) {
      getConversations(selectedSource, startDate, endDate);
    }
    // eslint-disable-next-line
  }, [selectedSource, startDate, endDate]);

  useEffect(() => {
    if (Conversations && Conversations[0]?.actualConversation) {
      // handleOpenedConversations(Conversations[0]?.actualConversation);
      let temp=[];
      const ConversationsValue=Conversations[0]?.actualConversation;
      for(let x in ConversationsValue)
      {
        let AIMessage=ConversationsValue[x].AIMessage;
        let HumanMessage=ConversationsValue[x].HumanMessage;
        if(HumanMessage)
        {
          temp.push({text:HumanMessage,role:'user'});
        }
        if(AIMessage)
        {
          temp.push({text:AIMessage,role:'ai'});
        }
        

      }
    
      setlogMessages(temp);
  
    }
    // eslint-disable-next-line
  }, [Conversations]);



  const SourceOptions = [
    { value: "API", label: "API" },
    { value: "Chatcells", label: "Chatcells" },
    { value: "Widget or Iframe", label: "Widget or Iframe" },
  ];

  const handleExport = () => {
    setIsOpen(!isOpen);
  };
  const handleJSONexport = () => {
    setIsOpen(false);
    // console.log(JSON.stringify(Conversations?.actualConversation));
    // logic for downloading json file of conversations
    if (Conversations && Conversations.length > 0) {
      const jsonData = JSON.stringify(Conversations,null,2);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const Jsonlink = document.createElement('a');
      Jsonlink.href = URL.createObjectURL(blob);
      Jsonlink.download = `${Conversations[0].conversationId}.json`;

      document.body.appendChild(Jsonlink);
      Jsonlink.click();
      document.body.removeChild(Jsonlink);
    }
  };
  // const handlePdfExport = () => {
  //   setIsOpen(false);
  // };

  const toggleSourceDropdown = () => {
    setSourceIsOpen(!SourceisOpen);
  };

  const handleSelectSourceOption = (value) => {
    if (selectedSource.length === 1 && selectedSource.includes(value)) {
      return; // Do nothing if there's only one source selected
    }
    // Toggle the selected source
    if (selectedSource.includes(value)) {
      setSelectedSource(selectedSource.filter((source) => source !== value));
    } else {
      setSelectedSource([...selectedSource, value]);
    }
  };

  const OpenConversation = (convo, i) => {
  
    // handleOpenedConversations(convo);
    let temp=[];
      for(let x in convo)
      {
        let AIMessage=convo[x].AIMessage;
        let HumanMessage=convo[x].HumanMessage;
        if(HumanMessage)
        {
          temp.push({text:HumanMessage,role:'user'});
        }
        if(AIMessage)
        {
          temp.push({text:AIMessage,role:'ai'});
        }
        

      }
    
      setlogMessages(temp);
    
    setSelectedConvo(i);
  };

  if (Object.values(pagesState).some((value) => value === true)) {
    return <div className="min-h-[80vh]"></div>;
  }
  if (loadingConversations) {
    return <SkeletonLogs />;
  }
  return (
    <div  ref={headerRef} className=" w-5/6 border-[1px] border-lime-500/25 rounded-md bg-white flex flex-col gap-5  my-16 md:my-10 mx-auto pb-5">
      <div className=" border-lime-500/25  border-b-[1px] justify-center py-3 ">
        <h3 className="text-xl font-semibold text-zinc-900 leading-6 mx-3">
          Chat logs
        </h3>
      </div>
      <div className="flex flex-col gap-2 justify-center px-4">
        <div className="flex flex-col xl:flex-row gap-2 justify-center lg:justify-between sm:items-center relative z-0">
          <div className="flex flex-col md:flex-row gap-2 md:gap-5 lg:items-center ">
            <div className="">
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
              />
            </div>
            <div className="dropdown cursor-pointer mt-6 relative ">
              <div
                className="dropdown-toggle border border-lime-500/25 py-1.5 px-3 rounded-md text-zinc-700 w-52 transition duration-300 ease-in-out text-sm font-semibold flex justify-between items-center "
                onClick={toggleSourceDropdown}
              >
                Source
                <TiArrowSortedDown className="text-lg opacity-70" />
              </div>
              {SourceisOpen && (
                <div className="dropdown-menu absolute top-12 right-7 md:right-1 border border-lime-500/25 bg-white z-20 w-11/12 py-2 px-1 rounded-md text-zinc-500 h-24 overflow-y-auto transition duration-300 ease-in-out shadow-xl">
                  {SourceOptions.map((source) => (
                    <label
                      key={source.value}
                      className="block text-zinc-700 mx-2"
                    >
                      <input
                        type="checkbox"
                        value={source.value}
                        checked={selectedSource.includes(source.value)}
                        onChange={() => handleSelectSourceOption(source.value)}
                        className="mr-2 bg-lime-500/25 "
                      />
                      {source.label}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="relative mt-3 md:mt-7">
            <button
              onClick={handleExport}
              className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out flex items-center justify-between gap-2"
            >
              Export
              <RiDownload2Fill />
            </button>
            {isOpen && (
              <div className="dropdown-menu absolute top-12 right-7 md:right-1 border border-lime-500/25 bg-white z-10 w-11/12 py-2 px-2 rounded-md text-zinc-300 overflow-y-auto transition duration-300 ease-in-out flex flex-col gap-1 shadow-xl ">
                {/* <button
                  onClick={handlePdfExport}
                  className="py-1 px-2 rounded-md text-black border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out flex items-center justify-between gap-2"
                >
                  PDF
                </button> */}
                <button
                  onClick={handleJSONexport}
                  className="py-1 px-2 rounded-md text-black text-sm border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out flex items-center justify-between gap-2"
                >
                  AS JSON
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full h-0.5 bg-lime-500/25 my-3 "></div>
        {Conversations && Conversations?.length > 0 ? (
          <div className="flex  flex-col md:flex-row gap-10 w-full justify-center py-2 ">
            <div className="flex flex-col gap-5 md:w-2/5 w-full overflow-scroll h-[600px] rounded-md">
              {Conversations?.map((message, index) => {
                return (
                  <div
                    className={`bg-lime-500/25 border border-lime-500/25 rounded-md py-2 px-3 flex flex-col cursor-pointer ${
                      selectedConvo === index
                        ? " border-lime-700 shadow-lg "
                        : ""
                    }`}
                    key={index}
                    onClick={() =>
                      OpenConversation(message?.actualConversation, index)
                    }
                  >
                    <h4 className="text-zinc-600 text-sm flex">
                      User:{" "}
                      {message?.actualConversation[0]?.HumanMessage?.slice(
                        0,
                        50
                      )}
                      <span
                        className={`${
                          message?.actualConversation[0]?.HumanMessage?.length >
                          50
                            ? " flex "
                            : " hidden "
                        }`}
                      >
                        ...
                      </span>
                    </h4>
                    <h4 className="text-sm flex">
                      Chatbot:{" "}
                      {message.actualConversation[0]?.AIMessage.slice(0, 110)}
                      ...
                      <span
                        className={`${
                          message.actualConversation[0]?.AIMessage.length > 110
                            ? " hidden "
                            : " hidden "
                        }`}
                      >
                        ...
                      </span>
                    </h4>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-6 border border-lime-500/25  h-[600px] overflow-y-scroll rounded-md   w-full md:w-2/3 py-2">
              <h3 className="text-sm font-semibold px-4 ">Source: Chatcells</h3>
          
                        <DeepChat
                        demo={true}
                style={{ height:'100%', border: 'none',backgroundColor:'transparent',fontSize:'13px',width:'100%',}}
                messageStyles={{
                  html: {shared: {bubble: {backgroundColor: '#BFFF00', paddingLeft: '4px',paddingBottom:'4px',paddingTop:'0px',width:'90%',lineHeight: '1.5', 
                        wordSpacing: '1.5px', textDecoration: 'underline',}} },
                        loading: {
                    bubble: {
                      color: 'white',
                      fontSize: '16px',
                      padding:'17px',
                      backgroundColor:'#F3F4F6'
                    },
                    error: {
                      bubble: { backgroundColor: "#f98e00", color: "white", fontSize: "15px" }
                    }
                  },
                 
                  default: {
                    ai: {
                      bubble: {
                        maxWidth: "80%",
                        lineHeight: '1.5', 
                        wordSpacing: '2px',
                         backgroundColor:'#F3F4F6',
                         padding:'15px',
                         margin:'10px'
                    
                      }
                    },
                    user: {
                      bubble: {
                       colorPicker :'rgb(132 204 22 / 0.25)' ,
                        maxWidth: "80%" ,
                        lineHeight: '1.5', 
                        wordSpacing: '2px',
                        color: '#3F6212',
                        padding:'15px',
                        backgroundColor:'rgb(132 204 22 / 0.25)',

                      }
                    }
                  }
                }}

             inputAreaStyle={{display:'none'}}
                initialMessages={logMessages}

              />
           
            </div>
          </div>
        ) : (
          <div className="flex w-full justify-center my-20">
            <h4 className="text-zinc-600 opacity-80 font-semibold my-16 md:my-0">
              No conversations found
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default Logs;


