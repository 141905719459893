import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useSharedState } from "../../Context/SharedStateContext";

const Share = () => {
  const [copyShare, setcopyShare] = useState(false);
  const { setPagesState, selectedChatbot } = useSharedState();

  const copyContent = (id) => {
    const element = document.getElementById(id);
    const tempInput = document.createElement("textarea");
    tempInput.value = element.textContent;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setcopyShare((prev) => !prev);
    setTimeout(() => {
      setcopyShare((prev) => !prev);
    }, 2000);
  };
  return (
    <>
      <div
        id="popup-modal"
        className="fixed top-10 right-0 left-0 z-50 mt-10 flex justify-center  w-full h-full bg-black bg-opacity-80"
      >
        <div className="bg-white  relative rounded-lg flex flex-col justify-center items-center gap-1 shadow-lg w-11/12 sm:w-[70%] md:w-[60%] p-4 md:h-48 h-52 mt-36">
          <button
            className="absolute top-0 right-0 mt-1 mr-1  hover:bg-lime-800 hover:text-white text-lime-800 bg-white shadow-lg rounded-full p-2"
            onClick={() => {
              setPagesState((prevState) => ({
                ...prevState,
                showSharePage: false,
              }));
            }}
          >
            <RxCross2 className="text-bold" />
          </button>
          <h3 className="font-bold text-gray-700 mt-4">www.chatcells.ai</h3>
          <div className="flex flex-col gap-2 w-11/12">
            <p className="text-sm m-1">
              To add chatbot anywhere on your website, add this iframe to your
              HTML code
            </p>
            <div
              id="copyShare"
              className="rounded-md flex justify-center items-center bg-zinc-100 p-1"
            >
              <pre className="m-1 text-xs sm:text-sm">
                <code className="whitespace-pre-wrap">
                  https://chatcells.ai/api/frame-assistant/
                  {selectedChatbot?.chatbotId}
                </code>
              </pre>
            </div>
            <div className="w-full flex gap-4 justify-center mt-2 mb-5 items-center">
              <button
                className="py-1 px-2 text-sm w-[100px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out flex justify-center items-center gap-2"
                onClick={() => copyContent("copyShare")}
              >
                {!copyShare ? (
                  <FaCopy className=" text-md mt-0.5" />
                ) : (
                  <TiTick className=" text-md bg-lime-500/25 mt-0.5" />
                )}
                Copy
              </button>

              <a
                href={`https://chatcells.ai/api/frame-assistant/${selectedChatbot?.chatbotId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="py-1 px-2 text-sm w-[100px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out flex justify-center items-center gap-2"
              >
                <FaArrowUpRightFromSquare className="text-md" />
                Visit
              </a>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Share;
