import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SignUp from './Authentication/SignUp';
import Login from './Authentication/LogIn';
import ForgotPassword from './Authentication/ForgotPassword';
import ResetPassword from './Authentication/ResetPassword';


const UserAuthentication = () => {
    const { section } = useParams();
    const [passwordValidation, setpasswordValidation] = useState("");
    const validateEmail=(email)=>{
      const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return regex.test(email);
    }
    const validatepassword = (password) => {
      const passwordPattern =
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
      if (passwordPattern.test(password)) {
        setpasswordValidation(""); // Email is valid, clear any previous validation message
      }
      else if(password.trim().length === 0){
        setpasswordValidation(""); 
      } else {
        setpasswordValidation(
          "Password length should be 8 and must contain one uppercase, one number and one symbol"
        );
      }
    };
    const getComponent = (section) => {
        switch (section) {
            case 'signup':
                return <SignUp passwordValidation={passwordValidation} validateEmail={validateEmail} validatepassword={validatepassword}/>;
            case 'login':
                return <Login />;
            case 'forgot-password':
                return <ForgotPassword />;
            case 'reset-password':
                return <ResetPassword />;
            default:
                return null; 
        }
    };

    return (
        <div className='bg-authb w-full flex gap-5 items-center justify-center'>
            <div className='w-11/12 md:w-3/4 xl:w-1/3 mt-8 lg:mt-0 flex justify-center'>
                {getComponent(section)}
            </div>
            <div className='flex items-center bg-lime-600'>
                {/* Placeholder content */}
            </div>
        </div>
    );
}

export default UserAuthentication;
