import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  function formatDateToYYYYMMDD(dateString) {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleStartDateChange = (date) => {
      setStartDate(formatDateToYYYYMMDD(date));
  };
  
  const handleEndDateChange = (date) => {
      setEndDate(formatDateToYYYYMMDD(date));
  };

  return (
    <div className="flex flex-col sm:items-center space-y-4">
      <div className="flex flex-col gap-2 sm:flex-row lg:space-x-4 sm:items-center">
        <div className="flex flex-col gap-1">
        <span className="text-sm font-semibold text-zinc-700 mx-1">From</span>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          className="px-4 py-1 rounded-lg border border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none "
          placeholderText="From Date"
        />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-semibold text-zinc-700 mx-1">To</span>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="px-4 py-1 rounded-lg border border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none "
          placeholderText="To Date"
          />
          </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
