import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import { useDataSourcesState } from "../../Context/DataSourcesContext";
import { useSharedState } from "../../Context/SharedStateContext";

const TotalChars = ({ setOpenBotPersonality, inCustomize }) => {
  const { chatbotDetails } = useSharedState();
  const {
    SourceData,
    totalCharacters,
    language,
    setLanguage,
    customPersonality,
    selectedPersonality,
    createChatbot,
    ChatbotData,
    AddedCharacters,
    RetrainChatbot,
    CreateChatbotloading,
    CreateChatbotprogress,
    UpdateChatbotloading,
    UpdateChatbotprogress,
  } = useDataSourcesState();
  const {
    FileList,
    MediaFiles,
    qaList,
    filechars,
    textchars,
    qaChars,
    linkchars,
    LinkList,
  } = SourceData ?? {};
  const {
    AddedFiles,
    AddedMediaFiles,
    AddedqaList,
    Addedfilechars,
    Addedtextchars,
    AddedqaChars,
    Addedlinkchars,
    AddedLinkList,
    AddedMediaFilechars,
  } = ChatbotData ?? {};
  const [error, setError] = useState(false);
  const [charLimitError, setCharLimitError] = useState(false); 
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (totalCharacters !== 0) {
      setError(false);
    }
    
  }, [totalCharacters, setError]);
  useEffect(() => {
    if (totalCharacters + AddedCharacters > 600000) {
      setCharLimitError(true);
    } else {
      setCharLimitError(false);
    }
  }, [totalCharacters, AddedCharacters]);
 
  const handleOpenModel = async () => {
    if (totalCharacters > 1000) {
      setError(false);
      setOpenBotPersonality(true);
    } else {
      setError(true);
    }
  };

  let totalSize = 0;
  MediaFiles.forEach((file) => {
    totalSize += file?.file?.size / 1024;
  });
  const checkStatus=()=>{
    return LinkList?.length > 15||charLimitError|| totalCharacters<1000;
  }

  return (
    <div
      className={` ${inCustomize ? "w-full" : " w-3/4 md:w-1/2 lg:w-11/12 "}`}
    >
      <div
        className={`w-full border border-lime-500/25 flex flex-col items-center rounded`}
      >
        <div
          className={`flex flex-col justify-center items-center w-full p-3 gap-1`}
        >
          <h2 className="font-bold text-center my-2 text-lime-700">Sources</h2>
          <div className="flex flex-row-reverse justify-between w-full">
            <div className="flex flex-col w-full mb-2">
              {inCustomize &&
                (FileList?.length > 0 ||
                  MediaFiles?.length > 0 ||
                  qaList?.length > 0 ||
                  textchars > 0 ||
                  LinkList.length > 0) && (
                  <h6 className="text-sm font-semibold text-zinc-600">
                    Latest Data
                  </h6>
                )}
              {FileList?.length > 0 && (
                <span className="text-sm my-1 text-gray-700">
                  {FileList.length > 1
                    ? `${FileList.length} Files `
                    : `${FileList.length} File `}
                  <span className="opacity-80">
                    ({filechars.toLocaleString("en-IN")} chars)
                  </span>
                </span>
              )}
              {MediaFiles?.length > 0 && (
                <span className="text-sm text-gray-700">
                  {MediaFiles.length > 1
                    ? `${MediaFiles.length} Media Files `
                    : `${MediaFiles.length} Media File `}
                  <span className="opacity-80">
                    ({Math.round(totalSize).toFixed(0)} KB)
                  </span>
                </span>
              )}
              {qaList?.length > 0 && (
                <span className="text-sm my-1 text-gray-700">
                  {qaList.length} Q&A{" "}
                  <span className="opacity-80">
                    ({qaChars?.toLocaleString("en-IN")} chars)
                  </span>
                </span>
              )}
              {textchars > 0 && (
                <span className="text-sm my-1 text-gray-700">
                  Text input{" "}
                  <span className="opacity-80">
                    ({textchars?.toLocaleString("en-IN")} chars)
                  </span>
                </span>
              )}
              {LinkList.length > 0 && (
                <span className="text-sm text-gray-700">
                  {LinkList.length > 1
                    ? `${LinkList.length} Links `
                    : `${LinkList.length} Link `}
                  <span className="opacity-80">
                    ({linkchars?.toLocaleString("en-IN")} chars)
                  </span>
                </span>
              )}
              {LinkList?.length > 15 && (
                <p className="text-red-500 text-xs mb-1 mt-2">
                  Only 15 links can be used for free plan
                </p>
              )}
            </div>
            {inCustomize && (
              <div className="flex flex-col w-full mb-2">
                <h6 className="text-sm font-semibold text-zinc-600">
                  Previous Data
                </h6>
                {AddedFiles?.length > 0 && (
                  <span className="text-sm my-1 text-gray-700">
                    {AddedFiles.length} File{" "}
                    <span className="opacity-80">
                      ({Addedfilechars.toLocaleString("en-IN")} chars)
                    </span>
                  </span>
                )}
                {AddedMediaFiles?.length > 0 && (
                  <span className="text-sm text-gray-700">
                    {AddedMediaFiles.length} Media File{" "}
                    <span className="opacity-80">
                      ({AddedMediaFilechars.toLocaleString("en-IN")} chars)
                    </span>
                  </span>
                )}
                {AddedqaList?.length > 0 && (
                  <span className="text-sm my-1 text-gray-700">
                    {AddedqaList.length} Q&A{" "}
                    <span className="opacity-80">
                      ({AddedqaChars?.toLocaleString("en-IN")} chars)
                    </span>
                  </span>
                )}
                {Addedtextchars > 0 && (
                  <span className="text-sm my-1 text-gray-700">
                    Text input{" "}
                    <span className="opacity-80">
                      ({Addedtextchars?.toLocaleString("en-IN")} chars)
                    </span>
                  </span>
                )}
                {AddedLinkList?.length > 0 && (
                  <span className="text-sm text-gray-700">
                    {AddedLinkList.length} Links{" "}
                    <span className="opacity-80">
                      ({Addedlinkchars?.toLocaleString("en-IN")} chars)
                    </span>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="w-full mb-3">
            <div className="flex flex-col gap- items-center w-full">
              <h2 className="font-semibold my-1 text-sm w-full">
                Total characters{" "}
              </h2>
              <p className="text-sm px-1 w-full ">
                {inCustomize
                  ? `(Previous) ${AddedCharacters?.toLocaleString("en-IN")} ${
                      totalCharacters !== 0
                        ? "+ (Latest) " +
                          totalCharacters?.toLocaleString("en-IN")
                        : ""
                    } / 5,00,000 limit`
                  : `${totalCharacters?.toLocaleString(
                      "en-IN"
                    )} / 5,00,000 limit`}
              </p>
              {error && (
                <p className="text-red-500 text-xs mb-1 mt-2 w-full">
                  Please provide atleast 1000 chars
                </p>
              )}
              {charLimitError && (
                <p className="text-red-500 text-xs mb-1 mt-2 w-full">
                  Maximum character limit exceeded (6,00,000 characters)
                </p>
              )}
            </div>
            {!inCustomize && (
              <div className=" flex-col gap-2 my-6 justify-center items-center hidden">
                <label
                  htmlFor="language"
                  className="font-semibold text-sm w-full"
                >
                  Choose language
                </label>
                <select
                  id="language"
                  name="language"
                  value={inCustomize ? chatbotDetails.language : language}
                  onChange={handleLanguageChange}
                  className="py-1.5 px-4 rounded-md w-full text-sm font-zinc-700 font-medium"
                >
                  <option value="English">English</option>
                  <option value="German">German</option>
                  <option value="French">French</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
            )}
          </div>
          {!inCustomize && (
            <div className="flex flex-col gap-1 w-full ">
              <h4 className="font-semibold text-sm">ChatBot Personality</h4>
              <div className="w-full flex gap-2 items-center  ">
                <h6 className="text-sm text-zinc-600 ">
                  {inCustomize
                    ? chatbotDetails.personality
                    : customPersonality
                    ? customPersonality
                    : selectedPersonality?.name}
                </h6>
                <button
                  className="text-sm font-semibold text-lime-500 hover:text-lime-700 bg-none border-none"
                  onClick={handleOpenModel}
                >
                  (change)
                </button>
              </div>
            </div>
          )}
          <div className="w-11/12 mt-5 ">
            <Button
              title={`${inCustomize ? "Retrain Chatbot" : "Create Chatbot"}`}
              Loading={
                inCustomize ? UpdateChatbotloading : CreateChatbotloading
              }
              LoadingTitle={`${
                inCustomize ? "Retraining Chatbot" : "Creating Chatbot"
              }`}
              disabled={checkStatus()}
              handleOnClick={async () => {
                const user = localStorage.getItem("user");
                if (!inCustomize) {
                  await createChatbot(user);
                  navigate("/my-chatbots");
                } else {
                  await RetrainChatbot(chatbotDetails, user);
                  navigate("/chatbot/dashboard/info");
                }
              }}
              type="button"
              className="w-full"
            />
          </div>
          {inCustomize
            ? UpdateChatbotprogress >= 0 && UpdateChatbotprogress <= 100
            : CreateChatbotprogress >= 0 &&
              CreateChatbotprogress <= 100 && (
                <div className="relative w-full mt-1 mb-6">
                  {inCustomize
                    ? UpdateChatbotloading
                    : CreateChatbotloading && (
                        <>
                          <span className="text-lime-700 text-xs">
                            {inCustomize
                              ? UpdateChatbotprogress
                              : CreateChatbotprogress}
                            %
                          </span>
                          <div className="overflow-hidden h-2 mt-1 text-xs flex rounded bg-lime-100">
                            <div
                              style={{
                                width: `${
                                  inCustomize
                                    ? UpdateChatbotprogress
                                    : CreateChatbotprogress
                                }%`,
                              }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-lime-500 "
                            ></div>
                          </div>
                        </>
                      )}
                </div>
              )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default TotalChars;
