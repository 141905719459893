import React from "react";
import PropTypes from "prop-types";
import { ImSpinner9 } from "react-icons/im";

const Button = ({
  title,
  handleOnClick,
  type,
  className,
  Loading,
  disabled,
  LoadingTitle,
}) => {
  return (
    <button
      type={type}
      className={`py-1 px-4 rounded-md   border-2 border-transparent ${
        (!Loading && !disabled)
          ? "hover:border-lime-800 hover:text-lime-700 bg-lime-700 hover:bg-transparent text-white "
          : " cursor-not-allowed bg-lime-500/25 text-lime-700"
      } font-semibold transition duration-200 ease-in-out flex justify-center items-center text-sm sm:text-base ${className}`}
      onClick={handleOnClick}
      disabled={Loading || disabled}
    >
      {Loading && LoadingTitle && LoadingTitle.length > 0 ? (
        <div className="flex gap-2 items-center">
          {LoadingTitle}
          <ImSpinner9 className="animate-spin text-lime-700 text-xl" />
        </div>
      ) : (
        title
      )}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  className: PropTypes.string,
  LoadingTitle: PropTypes.string,
  Loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  className: "",
  LoadingTitle: "",
  Loading: false,
  disabled: false,
};

export default Button;
