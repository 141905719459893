import React, { useEffect, useState } from "react";
import BoxLayout from "./BoxLayout";
import { useSharedState } from "../../../Context/SharedStateContext";
import SkeletonCustomize from "../../LoadingComponents/SkeletonCustomize";

const DynamicComponent = ({
  localGeneralSettings,
  setLocalGeneralSettings,
  domains,
  setDomains,
}) => {
  const handleNameChange = (event) => {
    const newValue = event.target.value;

    // Check if the new value is not empty
    if (newValue.trim().length > 0) {
      setLocalGeneralSettings((prevSettings) => ({
        ...prevSettings,
        Name: newValue,
      }));
    }
  };
  const handleVisibilityChange = (event) => {
    setLocalGeneralSettings((prevSettings) => ({
      ...prevSettings,
      Visibility: event.target.value,
    }));
  };
  const handleDomainChange = (event) => {
    // Split the textarea value by new lines and filter out empty lines
    const newDomains = event.target.value
      .split("\n")
      .filter((domain) => domain.trim() !== "");
      setLocalGeneralSettings({
        ...localGeneralSettings,
        Domains: newDomains.join(','),
      });
      // console.log(localGeneralSettings.Domains," ", newDomains)
    setDomains(newDomains); // Update domains state with the array of domains
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of Enter key press
      const newDomains = [...domains, ""];
      setLocalGeneralSettings({
        ...localGeneralSettings,
        Domains: newDomains.join(','),
      });
      setDomains(newDomains);
    }
  };
  // const handleMessagesLimitChange = (event) => {
  //   setLocalGeneralSettings((prevSettings) => ({
  //     ...prevSettings,
  //     MessageLimit: event.target.value,
  //   }));
  // };
  // const handleTimeChange = (event) => {
  //   setLocalGeneralSettings((prevSettings) => ({
  //     ...prevSettings,
  //     Time: event.target.value,
  //   }));
  // };
  const handleMessagesLimitChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      setLocalGeneralSettings({
        ...localGeneralSettings,
        MessageLimit: parseInt(value),
      });
    }
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      setLocalGeneralSettings({
        ...localGeneralSettings,
        Time: parseInt(value),
      });
    }
  };
  const handleshowmessageChange = (event) => {
    const newValue = event.target.value;

    // Check if the new value is not empty
    if (newValue.trim().length > 0) {
      setLocalGeneralSettings((prevSettings) => ({
        ...prevSettings,
        ShowMessage: newValue,
      }));
    }
  };
  const resetSettings = () => {
    // Reset MessageLimit and Time to default values
    setLocalGeneralSettings((prevSettings) => ({
      ...prevSettings,
      MessageLimit: 20, // Set to your default value
      Time: 240, // Set to your default value
    }));
  };
  return (
    <div className="py-4 px-4 flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <label
          htmlFor="name"
          className="text-sm text-zinc-800 font-medium mb-1"
        >
          Name
        </label>
        <input
          type="text"
          value={localGeneralSettings.Name}
          onChange={handleNameChange}
          className="border border-lime-500/25 py-2 px-4 rounded-md  focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-800"
        />
      </div>
      <div className="flex flex-col gap-2 mt-2 justify-center ">
        <label
          htmlFor="visibility"
          className="text-sm text-zinc-800 font-medium"
        >
          Visibility
        </label>
        <select
          id="visibility"
          name="visibility"
          value={localGeneralSettings.Visibility}
          onChange={handleVisibilityChange}
          className="py-2 px-4 rounded-md w-full text-sm font-zinc-700 font-medium border-lime-500/25 border focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-800"
        >
          <option value="Private">Private</option>
          <option value="Public">Public</option>
        </select>
        <p className="text-xs text-zinc-500 font-medium">
          'private': No one can access your chatbot except you (your account)
        </p>
        <p className="text-xs text-zinc-500 font-medium">
          'public': Other people can chat with your chatbot if you send them the
          link. You can also embed it on your website so your website visitors
          are able to use it.
        </p>
      </div>
      <div className=" mb-2">
        <div className="flex items-center justify-between my-2">
          <label className=" text-sm font-medium text-gray-700">Domains</label>
        </div>
        <textarea
          name="initialMessage"
          rows="4"
          placeholder="www.example.com"
          className=" block w-full rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
          value={domains.join("\n")}
          onChange={handleDomainChange}
          onKeyDown={handleKeyDown}
        ></textarea>
        <p className="text-xs text-zinc-500 font-medium">
          Enter each domain in a new line
        </p>
        <div className="mt-3">
          <h3 className=" text-zinc-600 font-medium">Your Domains:</h3>
          {domains.map((domain, index) => (
            <div key={index} className="text-sm text-gray-700">
              {index + 1}, {domain}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-sm text-zinc-800 font-medium">Rate limiting</h2>
          <button
            onClick={resetSettings}
            className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
          >
            Reset
          </button>
        </div>
        <p className="text-sm text-zinc-500 font-medium">
          Limit the number of messages sent from one device on the iframe and
          chat bubble (this limit will not be applied to you on chatbase.co,
          only on your website for your users to prevent abuse).
        </p>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-1 items-center">
          <div className="flex gap-1 items-center">
            <label
              htmlFor="messages"
              className="text-sm text-zinc-700 font-medium"
            >
              Limit to only
            </label>
            <input
              type="number"
              name="messages"
              id="messages"
              min={0}
              value={localGeneralSettings.MessageLimit}
              onChange={handleMessagesLimitChange}
              onKeyDown={(e) => {
                if (
                  !(
                    (e.keyCode > 95 && e.keyCode < 106) ||
                    (e.keyCode > 47 && e.keyCode < 58) ||
                    e.keyCode === 8
                  )
                ) {
                  e.preventDefault();
                }
              }}
              className="w-20 py-1 px-2 rounded-md border-lime-500/25 border focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-600"
            />
          </div>
          <div className="flex gap-1 items-center">
            <label htmlFor="time" className="text-sm text-zinc-700 font-medium">
              messages every
            </label>
            <input
              type="number"
              name="time"
              id="time"
              min={0}
              value={localGeneralSettings.Time}
              onChange={handleTimeChange}
              onKeyDown={(e) => {
                if (
                  !(
                    (e.keyCode > 95 && e.keyCode < 106) ||
                    (e.keyCode > 47 && e.keyCode < 58) ||
                    e.keyCode === 8
                  )
                ) {
                  e.preventDefault();
                }
              }}
              className="w-20 py-1 px-2 rounded-md border-lime-500/25 border focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-600"
            />
            <span className="text-sm text-zinc-700 font-medium">seconds</span>
          </div>
        </div>
        <div className="flex flex-col gap-1 my-2">
          <label
            htmlFor="showmessage"
            className="text-sm text-zinc-800 font-medium mb-1"
          >
            Show this message to show when the limit is hit
          </label>
          <input
            type="text"
            value={localGeneralSettings.ShowMessage}
            onChange={handleshowmessageChange}
            minLength={3}
            className="border border-lime-500/25 py-2 px-4 rounded-md  focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-800"
          />
        </div>
      </div>
    </div>
  );
};

const General = () => {
  const { customizeOptions, getGeneral, chatbotDetails ,loadingChatbotDetails } =
    useSharedState();

  const generalSettings = customizeOptions.General;
  const [localGeneralSettings, setLocalGeneralSettings] =
    useState(generalSettings);
  const [Loading, setLoading] = useState(false);
  const saveSettings = async () => {
    setLoading(true);
    await getGeneral(localGeneralSettings,chatbotDetails.chatbotId);
    setLoading(false);
  };


  const [domains, setDomains] = useState([]);
 

  useEffect(() => {
    if (chatbotDetails && chatbotDetails.chatbotUI) {
      setLocalGeneralSettings((prevSettings) => ({
        ...prevSettings,
        Name: chatbotDetails.chatbotName || prevSettings?.General?.Name,
        Visibility:
          chatbotDetails.visibility || prevSettings?.General?.Visibility,
        MessageLimit:
          chatbotDetails.rateLimitMessages ||
          prevSettings?.General?.MessageLimit,
        Time: chatbotDetails.rateLimitTime || prevSettings?.General?.Time,
       Domains: chatbotDetails.domains || prevSettings?.General?.domains,
      }));
      if(chatbotDetails.domains.trim()!=='')
      setDomains(chatbotDetails.domains.split(','));
    }
  
  }, [chatbotDetails]);
  

  if (loadingChatbotDetails) {
    return <SkeletonCustomize />;
  }

  return (
    <div className="w-11/12 lg:w-4/5">
      <BoxLayout
        Section="General"
        handleSaveSettings={saveSettings}
        Loading={Loading}
        dynamicComponent={
          <DynamicComponent
            localGeneralSettings={localGeneralSettings}
            setLocalGeneralSettings={setLocalGeneralSettings}
            domains={domains}
            setDomains={setDomains}
          />
        }
      />
    </div>
  );
};

export default General;
