import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import DataInput from "./Pages/DataInput";
import Header from "./Components/Header";
import Dashboard from "./Pages/Dashboard";
import { SharedStateProvider } from "./Context/SharedStateContext";
import GetChatbots from "./Pages/GetChatbots";
import LandingPage from "./Pages/LandingPage";
import Supportpage from "./Pages/Supportpage";
import UserAuthentication from "./Pages/UserAuthentication";
import ActivationPage from "./Pages/Authentication/ActivationPage";
import { AuthStateProvider } from "./Context/AuthenticationContext";
import { DataSourcesStateProvider } from "./Context/DataSourcesContext";
import { NotificationManager } from "./Components/UI/NotificationManager";
function App() {
  return (
    <AuthStateProvider>
      <DataSourcesStateProvider>
        <SharedStateProvider>
          <Router>
            <div className="App">
              <Header />
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route
                  exact
                  path="/auth/:section"
                  element={<UserAuthentication />}
                />
                <Route
                  exact
                  path="/auth/validation"
                  element={<ActivationPage />}
                />
                <Route exact path="/data-input" element={<DataInput />} />
                <Route exact path="/my-chatbots" element={<GetChatbots />} />
                <Route exact path="/support" element={<Supportpage />} />
                <Route
                  path="/chatbot/dashboard/:menu/:section?"
                  element={<Dashboard />}
                />
              </Routes>
              <NotificationManager />
            </div>
          </Router>
        </SharedStateProvider>
      </DataSourcesStateProvider>
    </AuthStateProvider>
  );
}

export default App;
