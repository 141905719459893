import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Images/chatcellsLogo.png";
import { RxCross2 } from "react-icons/rx";
import { HiMiniBars2 } from "react-icons/hi2";
import { useAuthState } from "../Context/AuthenticationContext";
const Header = () => {
  const location = useLocation();
  const {setuserEmailId,logInDataRef} = useAuthState();
  const userEmailId=localStorage.getItem('email');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showBorder, setShowBorder] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const headerRef = useRef(null);
  const [SelectedNav, setSelectedNav] = useState("Home");

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
    }
    if (location.pathname.includes("/auth")) {
      setShowBorder(false);
    } else {
      setShowBorder(true);
    }
    if(location.pathname.includes('/auth/login'))
    {
      setSelectedNav('Login');
    }
    else if(location.pathname.includes('/auth/logout'))
    {
   setSelectedNav('Logout');
    }
    else if(location.pathname.includes('/my-chatbots'))
    {
   setSelectedNav('Chatbots');
    }
    else if(location.pathname.includes('/support')){
      setSelectedNav('Support');
    }
    else
    {
      setSelectedNav('Home');
    }
  }, [setIsLoggedIn, location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) { // Assuming 640px is the sm breakpoint
        setShowHeader(false);
      }
    };

    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setShowHeader(false);
      }
    };

    // Initial call
    handleResize();

    // Add event listeners
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClickOutside);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem('email');
    setIsLoggedIn(false);
    setuserEmailId('');
    logInDataRef.current=''
  };

  const toggleHeader = (event) => {
    event.stopPropagation(); // Stop event propagation
    setShowHeader(!showHeader);
  };


  return (
    <div ref={headerRef}
      className={` ${
        !showBorder ? "border-none" : " border-b border-b-lime-500/25 "
      }  fixed top-0 w-full z-50 bg-white  `}
    >
      <div
        className={` px-2 py-4 md:p-5 ${
          showHeader
            ? " bg-lime-500/25 border-b border-b-lime-500/25"
            : "bg-white "
        }`}
      >
        <div
          className={` md:mx-6 flex gap-4 md:gap-2  ${
            showHeader
              ? " flex-col sm:flex-row  "
              : " justify-between items-center "
          }`}
        >
          <div className="flex items-center">
            <Link to="/" className="hover:text-black">
              <img src={logo} alt="Logo" className=" w-36 mr-2" />
            </Link>
          </div>
          {!showHeader && (
            <button
              className="sm:hidden fixed top-5 right-3 "
              onClick={toggleHeader}
            >
              <HiMiniBars2 className="text-2xl"/>
            </button>
          )}
          {showHeader && (
            <button
              className="sm:hidden hover:bg-white hover:text-lime-800 text-white transition duration-200 ease-in-out bg-lime-800 shadow-lg rounded-full p-1 fixed top-6 right-3"
              onClick={toggleHeader}
            >
              <RxCross2 className="text-bold" />
            </button>
          )}

          <nav
            className={`flex flex-col gap-2 justify-center sm:flex-row sm:items-center mx-2 md:mx-0 sm:space-x-8 text-lime-900 text-sm  sm:text-sm ${
              showHeader ? " mx-1 font-semibold " : " hidden sm:flex "
            } `}
          >
           { /*<Link
              to="/"
              className={` w-fit ${
                SelectedNav === "Home" ? " text-lime-800 " : " opacity-60   hover:opacity-100"
              }`}
              onClick={()=>handleSelectNav("Home")}
            >
              Home
            </Link>
            <Link to="/auth/signup" className={`hover:text-black ${isLoggedIn ? " hidden " : " "}`}>
            Register
          </Link> */}
            <Link
              to="/my-chatbots"
              className={`w-fit font-semibold  ${
                SelectedNav === "Chatbots" ? " text-lime-800 " : " opacity-60 hover:opacity-100"
              }`}
              >
              Chatbots
            </Link>
            {userEmailId && (
              <Link
              to="/support"
              className={`w-fit font-semibold ${
                SelectedNav === "Support" ? " text-lime-800 " : " opacity-60 hover:opacity-100"
              }`}
              >
                Support
              </Link>
            )}
            {isLoggedIn ? (
              <Link
                to="/auth/login"
                className={` w-fit font-semibold ${SelectedNav==='Logout'?'text-red-800':'hover:opacity-100 opacity-60'}  text-red-500`}
                onClick={()=>{
                  handleLogout();
                }}
              >
                Log Out
              </Link>
            ) : (
              <Link
                to="/auth/login"
                className={`w-fit font-semibold ${
                  SelectedNav === "Login" ? " text-lime-800 " : " opacity-60 hover:opacity-100 "
                }`}
              >
                Login
              </Link>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
