import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TotalChars from "../Components/FileUpload/TotalChars";
import FileSource from "../Components/FileUpload/FileSource";
import TextSource from "../Components/FileUpload/TextSource";
import WebsiteSource from "../Components/FileUpload/WebsiteSource";
import QASource from "../Components/FileUpload/QASource";
import DataInputOptions from "../Components/Chatbot_settings/NavBars/DataInputOptions";
import MediaFiles from "../Components/FileUpload/MediaFiles";
import BotPersonality from "./BotPersonality";
import { useDataSourcesState } from "../Context/DataSourcesContext";

const Main = ({ inCustomize }) => {
  const { SourceData, setTotalCharacters, ChatbotData, setAddedCharacters } =
    useDataSourcesState();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [openBotPersonality, setOpenBotPersonality] = useState(false);
  useEffect(() => {
    // Check if the required ID is present in local storage
    const user = localStorage.getItem("user");
    if (!user) {
      // If the ID is not present, redirect to login page
      console.log("no user");
      navigate("/auth/login");
    }
  }, [navigate]);

  useEffect(() => {
    const { filechars, textchars, qaChars, linkchars } = SourceData;
    setTotalCharacters(filechars + textchars + qaChars + linkchars);
    const {
      Addedfilechars,
      AddedqaChars,
      Addedlinkchars,
      Addedtextchars,
      AddedMediaFilechars,
    } = ChatbotData;
    setAddedCharacters(
      Addedfilechars +
        AddedMediaFilechars +
        AddedqaChars +
        Addedlinkchars +
        Addedtextchars
    );
  }, [SourceData, setTotalCharacters, ChatbotData, setAddedCharacters]);

  const [Source, setSource] = useState("Files");

  let sourceComponent;

  if (Source === "Files") {
    sourceComponent = <FileSource inCustomize={inCustomize} />;
  } else if (Source === "Text") {
    sourceComponent = <TextSource inCustomize={inCustomize} />;
  } else if (Source === "Q&A") {
    sourceComponent = <QASource inCustomize={inCustomize} />;
  } else if (Source === "Website") {
    sourceComponent = (
      <WebsiteSource
        setLoading={setLoading}
        Loading={Loading}
        inCustomize={inCustomize}
      />
    );
  } else if (Source === "Media") {
    sourceComponent = <MediaFiles inCustomize={inCustomize} />;
  }

  return (
    <>
      <div className={`fileupload ${inCustomize ? " w-[86%] mt-10 lg:mt-0" : " mt-28 "} `}>
        <div className={`lg:mr-12 flex justify-center flex-col ${inCustomize ? "hidden" : ""}`}>
          <h2 className={`text-2xl font-bold my-2 text-center text-lime-700`}>
            Data Sources
          </h2>
          <h4
            className={`mb-6 mx-1 text-xs font-semibold text-center text-gray-500`}
          >
            Train Your Chatbot: Upload and Enhance its Knowledge and Skills with
            Our Convenient Upload Page.
          </h4>
        </div>
        <div className={`flex flex-col justify-center items-center lg:my-8`}>
          <div
            className={` ${
              inCustomize
                ? "flex-col gap-1 sm:gap-1 lg:gap-4 w-full justify-center items-center"
                : " flex-col xl:flex-row w-11/12"
            }
              flex justify-center gap-2 xl:gap-6 `}
          >
            <div
              className={` ${
                inCustomize
                  ? "w-11/12 lg:w-4/5 rounded-md fixed z-10 md:pt-3 pb-5 bg-white  flex justify-center items-center top-20 md:mt-0"
                  : " flex lg:flex-col justify-center items-center lg:justify-start "
              }   `}
            >
              <DataInputOptions
                setSource={setSource}
                inCustomize={inCustomize}
              />
            </div>
            <div className={`lg:mr-12 ${inCustomize ? "mt-12 md:mt-10 lg:mt-20" : "hidden"}`}>
              <h2
                className={`text-2xl font-bold my-2 text-center text-lime-700`}
              >
                Data Sources
              </h2>
              <h4
                className={` mx-1 text-xs font-semibold text-center text-gray-500`}
              >
                Train Your Chatbot: Upload and Enhance its Knowledge and Skills
                with Our Convenient Upload Page.
              </h4>
            </div>
            <div
              className={`${
                inCustomize ? " gap-5 lg:items-start items-center " : " gap-5 xl:gap-16 "
              } flex flex-col lg:flex-row  w-full justify-center`}
            >
              <div
                className={` my-4 lg:my-0 ${
                  inCustomize ? "w-full lg:w-3/5 " : "w-full lg:w-2/3 "
                } flex  justify-center `}
              >
                <div className="border border-lime-500/25 w-full relative flex flex-col items-center rounded">
                  <div className="w-full">
                    <h2 className="text-xl font-semibold p-3 text-lime-800 bg-lime-500/25">
                      {Source}
                    </h2>
                    <div className="bg-lime-500/25 w-full h-px "></div>
                  </div>

                  <div className="w-full">{sourceComponent}</div>
                </div>
              </div>
              <div
                className={` ${
                  inCustomize ? " w-full md:w-1/2 lg:w-[35%] " : " lg:w-1/3 "
                }   flex justify-center lg:block `}
              >
                <TotalChars
                  Loading={Loading}
                  setOpenBotPersonality={setOpenBotPersonality}
                  inCustomize={inCustomize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openBotPersonality && (
        <>
          <div className="w-full h-full fixed top-0 left-0 bg-black opacity-80 z-10"></div>
          <div className="absolute top-10 z-30 flex justify-center items-center mt-10">
            <BotPersonality setOpenBotPersonality={setOpenBotPersonality} />
          </div>
        </>
      )}
    </>
  );
};

export default Main;
