import React from 'react'
import chatBotPng from "../../Images/ChatbogPng.png";

const SkeletonGetChatbots = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-4 gap-8 relative py-4 w-4/5">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
            className={`flex justify-center rounded-md relative duration-300 ease-in-out animate-pulse transition-all `}
            key={index}
          >
            <div
              className={`flex flex-col gap-4 border-lime-500/25 border p-4 rounded-md cursor-pointer relative z-50 w-full bg-lime-100 `}
              key={index}
            >
              <div className="bg-lime-500/25 text-lime-700 w-full text-center p-4 rounded-md flex justify-center items-center relative mb-8">
                <img
                  width={120}
                  src={chatBotPng}
                  alt=""
                  className={`relative opacity-0 h-24`}
                />
              </div>
              <p className="font-semibold flex text-center text-lime-700 flex-wrap">
              </p>
            </div>
          </div>
              ))
          }
            </div>
  )
}

export default SkeletonGetChatbots
