import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Settings from "./Dashboard_menu_pages/Settings";
import Info from "./Dashboard_menu_pages/Info";
import Logs from "./Dashboard_menu_pages/Logs";
import Leads from "./Dashboard_menu_pages/Leads";
import DashBoardOptions from "../Components/Chatbot_settings/NavBars/DashBoardOptions.js.js";
import { useSharedState } from "../Context/SharedStateContext.js";
import DataSources from "./Dashboard_menu_pages/DataSources.js";
import { RxCross2 } from "react-icons/rx";

const Dashboard = () => {
  const navigate = useNavigate();
  const { selectedChatbot, setIsSidebarOpen, isSidebarOpen, pagesState } =
    useSharedState();
  const [selectedMenu, setSelectedMenu] = useState("Info");
  const SideBarref = useRef(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      // If the ID is not present, redirect to login page
      navigate("/auth/login");
    }
    if (selectedChatbot === null) {
      navigate("/my-chatbots");
    }
  }, [navigate, selectedChatbot]);

  const toggleSidebar = (event) => {
    event.stopPropagation(); // Stop event propagation
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) { // Assuming 640px is the sm breakpoint
        setIsSidebarOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (SideBarref.current && !SideBarref.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    // Initial call
    handleResize();

    // Add event listeners
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClickOutside);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  return (
    <div
      className="flex mt-[78.066px] w-full"
      style={{ height: "calc(100vh - 78px)" }}
    >
      <div
        className={`w-44 bg-white  flex justify-center  fixed h-[calc(100vh-4rem)] top-[73.066px] left-0 z-20 ${
          isSidebarOpen ? "  " : " hidden lg:flex "
        }`}
      >
        <div ref={SideBarref} className="bg-lime-500/25 w-full border-r-lime-600/25 border-r overflow-scroll">
          <DashBoardOptions setSelectedMenu={setSelectedMenu} />
        </div>
      </div>
      <div className="w-full lg:ml-44 flex-1 justify-center mt- lg:mt-0">
        {!isSidebarOpen && (
          <button
            className="lg:hidden fixed top-[5rem] md:top-[6.7rem]  left-2 z-20"
            onClick={toggleSidebar}
          >
            <i className="fas fa-bars text-xl"></i>
          </button>
        )}
        {isSidebarOpen &&
          !Object.values(pagesState).some((value) => value === true) && (
            <button
              className="lg:hidden fixed top-20 md:top-[5.5rem] left-36 z-20 hover:bg-white hover:text-lime-800 text-white transition duration-200 ease-in-out bg-lime-800 shadow-lg rounded-full p-1"
              onClick={toggleSidebar}
            >
              <RxCross2 className="text-bold" />
            </button>
          )}
        {getMenuPage(selectedMenu)}
      </div>
    </div>
  );
};

const getMenuPage = (selectedMenu) => {
  switch (selectedMenu) {
    case "info":
      return <Info />;
    case "datasources":
      return <DataSources />;
    case "customize":
      return <Settings />;
    case "logs":
      return <Logs />;
    case "leads":
      return <Leads />;
    default:
      return <Info />;
  }
};

export default Dashboard;
