import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({
  label,
  name,
  value,
  placeholder,
  isVisible,
  passwordValidation,
  handleOnChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword);
  };

  return (
    <div className={` flex-col gap-2 ${isVisible ? "flex" : "hidden"}`}>
      <label htmlFor={name} className="flex font-medium ">
        {label}
      </label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          className="border border-lime-500/25 shadow-sm px-3 py-2 text-sm text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none rounded-md w-full"
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          minLength={5}
          required
        />
        <button
          onClick={togglePasswordVisibility}
          className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer opacity-40 hover:opacity-70"
        >
          {showPassword ? (
            <FaEye className="text-lg" />
          ) : (
            <FaEyeSlash className="text-lg" />
          )}
        </button>
      </div>
      <span className="text-red-500 text-sm">{passwordValidation}</span>
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

PasswordInput.defaultProps = {
  isVisible: true,
};

export default PasswordInput;
