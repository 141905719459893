import React, { useEffect, useState } from "react";
import Docxtemplater from "docxtemplater";
import { pdfjs } from "react-pdf";
import { useDropzone } from "react-dropzone";
import { useDataSourcesState } from "../../Context/DataSourcesContext";
import ConfirmModal from "../ConfirmModal";
import PizZip from "pizzip";
import { toast } from "react-toastify";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileSource = ({ inCustomize }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const { ChatbotData, setChatbotData, SourceData, setSourceData } =
    useDataSourcesState();
  const { FileList } = SourceData;
  const { AddedFiles } = ChatbotData;
  const [completedUploads, setCompletedUploads] = useState([]);

  const extractPdfText = async (objectUrl) => {
    const loadingTask = pdfjs.getDocument(objectUrl);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    let text = "";

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const content = await page.getTextContent();

      for (const item of content.items) {
        text += item.str + " ";
      }
    }

    return text;
  };
  const extractTextFromWordFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const content = e.target.result;
          let doc = new Docxtemplater(new PizZip(content), {
            delimiters: {
              start: "12op1j2po1j2poj1po",
              end: "op21j4po21jp4oj1op24j",
            },
          });
          let text = doc.getFullText();
          resolve(text);
        } catch (error) {
          toast.error("error extracting text from file")
          // reject(error);
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  const extractTextFromTextFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const text = event.target.result;
        resolve(text);
      };

      reader.readAsText(file);
    });
  };
  useEffect(() => {
    const calculateCharCount = async () => {
      const charCount = await FileList?.reduce(async (totalPromise, file) => {
        const total = await totalPromise;

        let text = "";
        if (file?.type === "application/pdf") {
          text = await extractPdfText(file);
        } else if (file?.text) {
          const trimmedText = file.text.split(/\s+/);
          text = trimmedText.join(" ");
        } else if (
          file?.type === "application/msword" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          text = await extractTextFromWordFile(file);
        }

        return total + text.length;
      }, Promise.resolve(0));

      setSourceData((prevSourceData) => ({
        ...prevSourceData,
        filechars: charCount,
      }));
    };

    calculateCharCount();
  }, [FileList, setSourceData]);
  const createObjectURL = (file) => {
    if (!file || !(file instanceof Blob || file instanceof File)) {
      console.error("Invalid file object:", file);
      return null;
    }
    return URL.createObjectURL(file);
  };
  const handleFileChange = async (acceptedFiles) => {
    let updatedFiles = [...SourceData.FileList]; // Initialize updated files array
    for (const file of acceptedFiles) {
      setCompletedUploads((prevCompletedUploads) => [
        ...prevCompletedUploads,
        file,
      ]);
      let charCount = 0;
      let text = "";
      let objectUrl = ""; // Create a variable to store the object URL
      if (file?.type === "application/pdf") {
        objectUrl = createObjectURL(file); // Create object URL once
        text = await extractPdfText(objectUrl); // Use the object URL in extractPdfText
        const trimmedText = text.split(/\s+/);
        text = trimmedText.join(" ");
        charCount = text.length;
      } else if (file?.type === "text/plain") {
        text = await extractTextFromTextFile(file);
      } else if (
        file?.type === "application/msword" ||
        file?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        text = await extractTextFromWordFile(file);
      }

      // Add the current file information to the updated files array
      updatedFiles = [
        ...updatedFiles,
        { file, charCount, progress: 100, text, objectUrl },
      ];
    }

    // Update the state with the updated files array
    setSourceData({
      ...SourceData,
      FileList: updatedFiles,
    });
  };

  const removeFile = (fileToRemove) => {
    setSourceData({
      ...SourceData,
      FileList: [...SourceData.FileList.filter((f) => f.file !== fileToRemove)],
    });
  };
  const handleDeleteModal = (fileToRemove) => {
    setFileToDelete(fileToRemove);
    setShowModal(true);
  };
  const handleConfirmDelete = (fileToRemove) => {
    const removedFileCount = fileToRemove.count;
    setChatbotData((prevState) => ({
      ...prevState,
      AddedFiles: prevState.AddedFiles.filter((f) => f !== fileToRemove),
      Addedfilechars: prevState.Addedfilechars - removedFileCount,
    }));
    setShowModal(false);
  };
  const handleCancelDelete = () => {
    setFileToDelete(null);
    setShowModal(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
    accept: ".pdf, .doc, .docx, .txt",
    multiple: true,
  });

  return (
    <div className="flex flex-col justify-center items-center">
      <div className={`flex flex-col items-center w-11/12`}>
        <div className="w-full">
          <div
            className="flex flex-col items-center justify-center mt-6 border border-lime-500/25 w-full p-6 cursor-pointer"
            {...getRootProps()}
          >
            <div className="relative w-16 h-16 rounded-full my-2 flex justify-center items-center">
              <div className="border-dotted animate-ping2 border border-lime-600 rounded-full absolute inset-2 m-auto delay-100"></div>
              <div className="border-dotted animate-ping border border-lime-600 rounded-full absolute inset-4 m-auto delay-300 "></div>
              <i className="fa-solid fa-arrow-up-from-bracket fa-lg  text-lime-700"></i>
            </div>
            <h4 className=" mt-2 text-center text-sm text-gray-600">
              Drag & drop your files here, or click to upload
              <p className="text-xs opacity-70 my-2 text-center">
                Supported file types: .pdf, .docs, .docx, .txt
              </p>
            </h4>
          </div>
          <input {...getInputProps()} accept=".pdf, .doc, .docx, .txt" />
          <div className="mt-2">
            {AddedFiles && inCustomize && (
              <>
                <div className="flex items-center justify-center mt-6 mx-2">
                  <span className="w-1/4 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                  <p className="opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
                    Trained Data files
                  </p>
                  <span className="w-1/4 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                </div>
                {AddedFiles.length > 0 ? (
                  <div className="flex flex-col justify-center items-center mt-2">
                    <ul className="w-full">
                      {AddedFiles?.map((file, index) => (
                        <li key={index} className="mb-2">
                          <div className="flex flex-col items-center">
                            <div className="flex w-full justify-between">
                              <div>
                                <i className="fa-regular fa-file opacity-60"></i>
                                <span className="text-xs break-words mx-2">
                                  {file.fileName} (
                                  {file.count.toLocaleString("en-IN")}{" "}
                                  characters)
                                </span>
                              </div>
                              <span className="text-xs my-2">
                                <button
                                  className="ml-8 hover:opacity-60 opacity-80"
                                  onClick={() => handleDeleteModal(file)}
                                >
                                  <i className="fa-solid fa-trash text-black"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                      {showModal && (
                        <>
                          <div className="w-screen h-screen fixed top-0 left-0 bg-black opacity-80 z-10"></div>
                          <div className="absolute top-10 left-44 z-20 flex justify-center">
                            <ConfirmModal
                              title={"Delete File ?"}
                              file={fileToDelete.fileName}
                              handleCancelDelete={handleCancelDelete}
                              handleConfirmDelete={() =>
                                handleConfirmDelete(fileToDelete)
                              }
                            />
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                ) : (
                  <h2 className="my-3 opacity-70 text-center text-xs mr-2">
                    No Files Found
                  </h2>
                )}
              </>
            )}
            {FileList && (inCustomize ? FileList.length > 0 : true) && (
              <>
                <div className="flex items-center justify-center mt-6 mx-2">
                  <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
                  <p className="opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
                    Uploaded files
                  </p>
                  <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
                </div>
                {FileList?.length > 0 ? (
                  <div className="flex flex-col justify-center items-center mt-2">
                    <ul className="w-full">
                      {FileList?.map(
                        (file, index) =>
                          file.file && (
                            <li key={index} className="mb-2">
                              <div className="flex flex-col items-center">
                                <div className="flex w-full justify-between">
                                  <a
                                    href={createObjectURL(file?.file)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer flex items-center hover:opacity-70"
                                  >
                                    <i className="fa-regular fa-file opacity-60"></i>
                                    <span className="text-xs break-words mx-2">
                                      {file?.file?.name}{" "}
                                      {Math.round(file?.file?.size / 1024).toFixed(0)} KB
                                    </span>
                                  </a>
                                  <span className="text-xs my-2">
                                    <button className="ml-8 hover:opacity-60 opacity-80">
                                      <i
                                        onClick={() => removeFile(file?.file)}
                                        className="fa-solid fa-trash text-black"
                                      ></i>
                                    </button>
                                  </span>
                                </div>
                                {file?.progress &&
                                  file?.progress < 100 &&
                                  !completedUploads.includes(file?.file) && (
                                    <div className="w-64 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full h-2 ml-2">
                                      <div
                                        className={`h-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full w-${file?.progress}`}
                                      ></div>
                                    </div>
                                  )}
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                ) : (
                  <h2 className="my-3 opacity-70 text-center text-xs mr-2">
                    No Files Uploaded
                  </h2>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileSource;
