import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiVideoUploadFill } from "react-icons/ri";
import { useDataSourcesState } from "../../Context/DataSourcesContext";
import ConfirmModal from "../ConfirmModal";


const MediaFiles = ({ inCustomize }) => {
  const { ChatbotData, setChatbotData, SourceData, setSourceData } = useDataSourcesState();
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const { MediaFiles } = SourceData;
  const { AddedMediaFiles } = ChatbotData;
  const [completedUploads, setCompletedUploads] = useState([]);
  const createObjectURL = (file) => {
    if (!file || !(file instanceof Blob || file instanceof File)) {
      console.error("Invalid file object:", file);
      return null;
    }
    return URL.createObjectURL(file);
  };
  const handleMediaFileChange = async (acceptedFiles) => {
    let updatedFiles = [...SourceData.MediaFiles]; 
    const newFiles = acceptedFiles.filter(
      (file) =>
        (file.type.startsWith("audio/") || file.type.startsWith("video/")) &&
        file.size <= 500 * 1024 * 1024
    );

    for (const file of newFiles) {
      setCompletedUploads((prevCompletedUploads) => [
        ...prevCompletedUploads,
        file,
      ]);
      updatedFiles = [
        ...updatedFiles,
        { file, progress: 100},
      ];
    }
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      MediaFiles:updatedFiles ,
    }));
  };

  const removeFile = (fileToRemove) => {
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      MediaFiles: [
        ...SourceData.MediaFiles.filter((f) => f.file !== fileToRemove),
      ],
    }));
  };
  const handleDeleteModal = (fileToRemove) => {
    setFileToDelete(fileToRemove);
    setShowModal(true);
  };
  const handleCancelDelete = () => {
    setFileToDelete(null);
    setShowModal(false);
  };
  const removeAddedMediaFile = (fileToRemove) => {
    const removedFileCount = fileToRemove.count;
    setChatbotData((prevChatbotData) => ({
      ...prevChatbotData,
      AddedMediaFiles: [
        ...ChatbotData.AddedMediaFiles.filter((f) => f !== fileToRemove),
      ],
      AddedMediaFilechars: prevChatbotData.AddedMediaFilechars - removedFileCount,
    }));
    setShowModal(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleMediaFileChange,
    accept: "audio/*,video/*",
    maxSize: 300 * 1024 * 1024,
    multiple: true,
  });
  return (
    <div className="flex flex-col justify-center items-center">
      <div className={`flex flex-col items-center w-11/12`}>
        <div className="w-full">
          <div
            className="flex flex-col items-center justify-center mt-6 border border-lime-500/25 w-full p-6 cursor-pointer"
            {...getRootProps()}
          >
            <div className="relative w-16 h-16 rounded-full my-2 flex justify-center items-center">
              <div className="border-dotted animate-ping2 border border-lime-600 rounded-full absolute inset-3 m-auto delay-100"></div>
              <div className="border-dotted animate-ping border border-lime-600 rounded-full absolute inset-2 m-auto delay-300 "></div>
              {/* <i className="fa-solid fa-arrow-up-from-bracket fa-lg  text-lime-700"></i> */}
              <RiVideoUploadFill className="text-3xl text-lime-700" />
            </div>
            <h4 className=" mt-2 text-center text-sm text-gray-600">
              Drag & drop your Media files here, or click to upload
              <p className="text-xs opacity-70 my-2 text-center">
                Supported file types: .mp4, webm, mp3, wav (max 500MB)
              </p>
            </h4>
          </div>
          <input {...getInputProps()} accept="audio/*,video/*" />
          <div className="mt-2">
          {AddedMediaFiles && inCustomize && (
              <>
                <div className="flex items-center justify-center mt-6 mx-2">
                  <span className="w-1/4 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                  <p className="opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
                    Trained Media files
                  </p>
                  <span className="w-1/4 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                </div>
                {AddedMediaFiles.length > 0 ? (
                  <div className="flex flex-col justify-center items-center mt-2">
                    <ul className="w-full">
                      {AddedMediaFiles?.map((file, index) => (
                        <li key={index} className="mb-2">
                          <div className="flex flex-col items-center">
                            <div className="flex w-full justify-between">
                              <div>
                                <i className="fa-regular fa-file opacity-60"></i>
                                <span className="text-xs break-words w-11/12 mx-2">
                                  {file.fileName}{file.count.toLocaleString("en-IN")}characters
                                </span>
                              </div>
                              <span className="text-xs my-2">
                                <button className="ml-8 hover:opacity-60 opacity-80">
                                  <i
                                    onClick={() => handleDeleteModal(file)}
                                    className="fa-solid fa-trash text-black"
                                  ></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                      {showModal && (
                        <>
                          <div className="w-screen h-screen fixed top-0 left-0 bg-black opacity-80 z-10"></div>
                          <div className="absolute top-10 left-44 z-20 flex justify-center">
                            <ConfirmModal
                              title={"Delete Media File ?"}
                              file={fileToDelete.fileName}
                              handleCancelDelete={handleCancelDelete}
                              handleConfirmDelete={()=>removeAddedMediaFile(fileToDelete)}
                            />
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                ) : (
                  <h2 className="my-3 opacity-70 text-center text-xs mr-2">
                    No Media Files Found
                  </h2>
                )}
              </>
            )}
            {MediaFiles && (inCustomize ? MediaFiles.length > 0 : true) && (
              <>
                <div className="flex items-center justify-center mt-6 mx-2">
                  <span className="w-1/5 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                  <p className="opacity-70 w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/3 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
                    Uploaded Media files
                  </p>
                  <span className="w-1/5 sm:w-1/3 md:w-1/3 h-px bg-lime-500/25"></span>
                </div>
                {MediaFiles?.length > 0 ? (
                  <div className="flex flex-col justify-center items-center mt-2">
                    <ul className="w-full">
                      {MediaFiles?.map(
                        (file, index) =>
                          file.file && (
                            <li key={index} className="mb-2">
                              <div className="flex flex-col items-center">
                                <div className="flex w-full justify-between">
                                  <a
                                    href={createObjectURL(file?.file)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer flex items-center hover:opacity-70"
                                  >
                                    <i className="fa-regular fa-file opacity-60"></i>
                                    <span className="text-xs break-words w-11/12 mx-2">
                                      {file?.file?.name}{" "}
                                      {Math.round(file?.file?.size / 1024).toFixed(0)} KB
                                    </span>
                                  </a>
                                  <span className="text-xs my-2">
                                    <button className="ml-8 hover:opacity-60 opacity-80">
                                      <i
                                        onClick={() => removeFile(file?.file)}
                                        className="fa-solid fa-trash text-black"
                                      ></i>
                                    </button>
                                  </span>
                                </div>
                                {file?.progress &&
                                  file?.progress < 100 &&
                                  !completedUploads.includes(file?.file) && (
                                    <div className="w-64 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full h-2 ml-2">
                                      <div
                                        className={`h-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full w-${file?.progress}`}
                                      ></div>
                                    </div>
                                  )}
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                ) : (
                  <h2 className="my-3 opacity-70 text-center text-xs mr-2">
                    No Media Files Uploaded
                  </h2>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaFiles;
