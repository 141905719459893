import React, { useState, useEffect } from "react";
import { ImSpinner9 } from "react-icons/im";

const BoxLayout = ({
  Section,
  dynamicComponent,
  handleSaveSettings,
  Loading,
}) => {
  const [buttonStatus, setButtonStatus] = useState(false);
  

useEffect(() => {
  const handleChange = () => {
    setButtonStatus(true);
    document.removeEventListener("change", handleChange);
  };


  setButtonStatus(false);
  document.addEventListener("change", handleChange);

  return () => {
    document.removeEventListener("change", handleChange);
  };
}, []); // Empty dependency array to run only once on component mount


  return (
    <div className="border border-lime-500/25 rounded  flex flex-col justify-center w-full">
      <h2 className="border-b border-b-lime-500/25 py-2 px-4 text-xl text-zinc-800 font-semibold">
        {Section}
      </h2>
      {dynamicComponent}
      <div className="bg-lime-500/25 flex justify-end py-3 px-4">
        <button
          onClick={handleSaveSettings}
          disabled={!buttonStatus || Loading}
          className={`py-1 px-4 rounded-md flex  text-white border-2 border-transparent font-semibold transition duration-200 ease-in-out ${
            buttonStatus && !Loading
              ? "bg-lime-700 hover:border-lime-800 hover:bg-transparent hover:text-lime-800"
              : "bg-lime-700 opacity-50 cursor-not-allowed"
          }`}
        >
        
            <span>Save</span>
          {Loading && 
            <div className="flex ml-1 gap-2 items-center px-1.5 py-0.5">
              <ImSpinner9 className="animate-spin text-white text-xl" />
            </div>
          }
        </button>
      </div>
    </div>
  );
};

export default BoxLayout;
