import React, { useState } from "react";
import General from "../../Components/Chatbot_settings/Customize_menus/General";
import Model from "../../Components/Chatbot_settings/Customize_menus/Model";
import ChatUI from "../../Components/Chatbot_settings/Customize_menus/ChatUI";
import LeadsUI from "../../Components/Chatbot_settings/Customize_menus/LeadsUI";
import DataSources from "./DataSources.js";
import CustomizeOptions from "../../Components/Chatbot_settings/NavBars/CustomizeOptions.js";
import { useSharedState } from "../../Context/SharedStateContext.js";

const Settings = () => {
  const [selectedSection, setSelectedSection] = useState("general");
  const {pagesState}=useSharedState()
  if (Object.values(pagesState).some((value) => value === true)) {
    return (
      <div className='min-h-[80vh]'></div>
    )
  }
  return (
    <div className="flex flex-col items-center gap-10 mb-5 ">
      <div className={`fixed z-10  pb-5 md:pt-3 md:pb-0  lg:pt-3 lg:pb-5 bg-white  flex justify-center items-center w-full md:mt-0 lg:w-[calc(100vw-12.5rem)] `} >
        <CustomizeOptions setSelectedSection={setSelectedSection} />
      </div>
      <div className="w-full flex justify-center items-center mt-20 sm:mt-20 md:mt-24 lg:mt-24 mb-6">
        {getContent(selectedSection)}
      </div>
    </div>
  );
};

// Helper function to render content based on selected section
const getContent = (selectedSection) => {
  switch (selectedSection) {
    case "general":
      return <General />;
    case "model":
      return <Model />;
    case "chatui":
      return <ChatUI />;
    case "datasources":
      return <DataSources />;
    case "leadsui":
      return <LeadsUI />;
    default:
      return <General />;
  }
};

export default Settings;
