import React from "react";
import { Link } from "react-router-dom";
import Input from "../../Components/UI/Input";
import PasswordInput from "../../Components/UI/PasswordInput";
import Button from "../../Components/UI/Button";
// import Oauth from "../../Components/UI/Oauth";

const AuthLayout = ({
  heading,
  credentials,
  handleOnChange,
  passwordValidation,
  handleSubmit,
  actionText,
  actionLink,
  check,
  fromSignInPage,
  showConfirmationMessasge,
  showVerificationMessasge,
  Loading
}) => {
  // const handleGoogleSignIn = () => {};
  return (
    <div className=" flex items-center">
      <div className=" flex justify-center items-center mt-12">
        <form
          onSubmit={handleSubmit}
          className="w-11/12 mt-10  rounded-lg border border-lime-500/25"
        >
          <div className=" px-12 py-3 rounded-lg">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <h2 className="  text-xl  sm:text-2xl font-semibold text-center flex items-center gap-2 justify-center">
                  {heading}
                </h2>
                <h6
                  className={`bg-lime-500/25 rounded-lg text-sm text-zinc-700 py-3 px-4 mt-2 ${
                    showConfirmationMessasge ? "" : "hidden"
                  }`}
                >
                  We have sent a confirmation mail to your email address, please
                  check (valid for 24hrs )
                </h6>
                <h6
                  className={`bg-lime-500/25 rounded-lg text-sm text-zinc-700 py-3 px-4 mt-2 ${
                    showVerificationMessasge ? "" : "hidden"
                  }`}
                >
                  Your email is not verified, We have sent a verification mail
                  to your email address, (valid for 24hrs only)
                </h6>
              </div>
              <div>
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  value={credentials?.email}
                  handleOnChange={handleOnChange}
                  placeholder="example@domain.com"
                  required={true}
                  minLength={5}
                />
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <PasswordInput
                    label="Password"
                    name="password"
                    placeholder="Password"
                    value={credentials?.password}
                    passwordValidation={passwordValidation}
                    handleOnChange={handleOnChange}
                  />
                  <div
                    className={`flex justify-end ${
                      fromSignInPage ? "  " : " hidden "
                    }`}
                  >
                    <Link
                      to={"/auth/forgot-password"}
                      className="text-blue-500 mt-1 text-sm font-medium justify-end hover:opacity-75"
                    >
                      Forgot password ?
                    </Link>
                  </div>
                </div>
                {!fromSignInPage && (
                  <div>
                    <PasswordInput
                      label="Confirm Password"
                      name="cpassword"
                      placeholder="Confirm Password"
                      value={credentials?.cpassword}
                      handleOnChange={handleOnChange}
                    />
                    <p
                      className={`${
                        credentials?.password === credentials?.cpassword ||
                        credentials?.cpassword.length === 0
                          ? " hidden "
                          : ""
                      } text-red-500 text-sm float-right`}
                    >
                      Password mismatch
                    </p>
                  </div>
                )}
              </div>
              <Button
                className={`mt-2 w-full text-lg`}
                title={actionText}
                Loading={Loading}
                type="submit"
                LoadingTitle='Please wait...'
              />
              <div className="flex flex-col gap-5">
                {/* <div className="flex justify-center items-center">
                  <span className="w-1/2 mr-2 bg-zinc-500 h-[1.5px] opacity-70"></span>
                  <p className=" text-sm font-semibold"> OR</p>
                  <span className=" w-1/2 ml-2 bg-zinc-500 h-[1.5px] opacity-70"></span>
                </div>
                <div>
                  <Oauth
                    handleSignIn={handleGoogleSignIn}
                    isOauthVisible={true}
                  />
                </div> */}
                <div className="flex flex-col mt-3">
                  <div className="flex gap-0.5 justify-center">
                    <h6>{check}</h6>
                    <Link
                      className="mx-1 underline font-bold hover:opacity-70"
                      to={actionLink}
                    >
                      {fromSignInPage ? "Sign Up" : "Log In"}
                    </Link>
                  </div>
                  <p className={` my-4 text-center `}>
                    By continuing you agree to our{" "}
                    <span className="underline font-semibold cursor-pointer hover:opacity-70">
                      Terms of Service
                    </span>{" "}
                    and{" "}
                    <span className="underline font-semibold cursor-pointer hover:opacity-70">
                      Privacy Policy
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthLayout;
