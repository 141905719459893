import React, { useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { Link } from "react-router-dom";
import { useAuthState } from "../../Context/AuthenticationContext";

const ForgotPassword = () => {
  const { SendConfirmationMail, Loading, sendCodeData } = useAuthState();
  const [email, setEmail] = useState("");
  const [showVerificationMessasge, setshowVerificationMessasge] =
    useState(false);
  const handleContinue = async(e) => {
    e.preventDefault();
    try {
      await SendConfirmationMail(email, 'Password')
      if (sendCodeData.responseCode === 200){
        setshowVerificationMessasge(true)
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
      <div className=" flex justify-center items-center mt-12 w-11/12 sm:5/6 md:w-3/4 xl:w-full">
        <form
          onSubmit={handleContinue}
          className="w-11/12 my-10 border border-lime-500/25 rounded-lg"
        >
          <div className=" px-6 sm:px-12 py-5 rounded-lg">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <h2 className="text-lg sm:text-2xl font-semibold text-center flex flex-col sm:flex-row items-center gap-2 justify-center">
                  <span
                    className={` text-orange-400 `}
                  >
                    <IoWarningOutline />
                  </span>{" "}
                 <span> Forgot Password ?</span>
                </h2>
                <h6 className={`bg-lime-500/25 rounded-lg text-sm text-zinc-700 py-3 px-4 mt-2 ${showVerificationMessasge ? '' : 'hidden'}`}>
                  We have resent a Verification mail to your email address to verify, (valid for 24hrs only)
                </h6>
              </div>
                <div className="flex flex-col gap-7">
                  <div className="w-full">
                    <Input
                      label="Email"
                      name="email"
                      type="email"
                      value={email}
                      handleOnChange={(e) => setEmail(e.target.value)}
                      placeholder="example@domain.com"
                      required={true}
                      minLength={5}
                    />
                    <h6 className={`text-zinc-700 text-xs mt-2 text-center`}>
                      you will recieve a code to this email for confirmation
                    </h6>
                  </div>
                  <Button className={`mt-2 w-full text-lg`} Loading={Loading} title={"Continue"} type="submit" LoadingTitle='Please wait...' />
                </div>
              <h3 className="text-center">
                <span className={`text-xl `}>&larr;</span> Back to
                <Link
                  className="mx-2 underline font-bold hover:opacity-70"
                  to={"/auth/login"}
                >
                  LogIn
                </Link>
              </h3>
            </div>
          </div>
        </form>
      </div>
  );
};

export default ForgotPassword;
