import React from "react";
import { useSharedState } from "../../Context/SharedStateContext";
import chatbotPic from '../../Images/chatbot_pic1.png'
import Consultation from "../../Components/UI/Consultation";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const { pagesState, setPagesState } = useSharedState();
  const navigate=useNavigate();
  const handleComponent = (name) => {
    if (name === "Consultation") {
      setPagesState((prev) => ({
        ...prev,
        showConsultationPage: !prev.showConsultationPage,
      }));
    }
  };
  const Tryforfree=()=>{
    const user=localStorage.getItem('user');
    if(user)
    {
      navigate('/my-chatbots');
    }
    else{
      navigate('/auth/login');
    }
  }
  return (
    <div className="flex gap-10 flex-col lg:flex-row  lg:items-center lg:justify-around  lg:px-24 px-10 mt-32 min-h-[71vh]">
      <div className="lg:w-1/2 justify-center items-center md:justify-start md:items-start flex flex-col gap-5">
        <h1 className="lg:text-6xl text-5xl text-center md:text-left font-bold pb-4 bg-clip-text text-transparent bg-gradient-to-b from-lime-500 to-lime-800">
          Create AI powered Chatbots for your website
        </h1>
        <h5 className="lg:text-lg   font-bold text-lime-600 max-w-lg">
          Build an AI-powered chatbot, integrate with your website in a few
          minutes and let it automate customer support, 
          drive sales, engage with the users and do much more.
        </h5>
        <div className="flex flex-col md:flex-row justify-center items-center md:justify-start md:items-start gap-2.5 md:gap-2  ">
          <button className="lg:text-md text-sm w-[210px] md:w-fit font-semibold py-2 px-1 sm:px-4 sm:py-2 bg-lime-700 text-white hover:bg-transparent hover:text-lime-700 border-2 border-lime-700 rounded-md transition duration-300 ease-in-out"
          onClick={()=>Tryforfree()}
          >
            Try for free! <span className="hidden sm:inline">&rarr;</span>
          </button>
          <button
            className="lg:text-md text-sm w-[210px] md:w-fit  items-center   font-semibold py-2 px-1 sm:px-4 sm:py-2 bg-lime-700 text-white hover:bg-transparent hover:text-lime-700 border-2 border-lime-700 rounded-md transition duration-300 ease-in-out"
            onClick={() => handleComponent("Consultation")}
          >
            Free consultation  <span className="text-sm mr-1" >(30 mins) </span>{"  "}
            <span className="text-md hidden sm:inline ">&rarr;</span>
          </button>
        </div>
      </div>
      <div className=" w-full  lg:w-[45%] ml-4 md:ml-0 mt-8 lg:mt-16 flex justify-center items-center ">
        <img src={chatbotPic} alt="pic" className="w-full sm:w-[70%] mr-5 lg:mr-0"/>
      </div>
      {pagesState.showConsultationPage && (
        <Consultation showConsultationPage={pagesState.showConsultationPage} />
      )}
    </div>
  );
};

export default WelcomePage;
