import React from "react";
import { RxCross2 } from "react-icons/rx";
import { GiCancel } from "react-icons/gi";
import { MdDeleteOutline } from "react-icons/md";
import { GoAlert } from "react-icons/go";

import { useSharedState } from "../../Context/SharedStateContext";
import { useNavigate } from "react-router-dom";

const Delete = ({ showdeletePage }) => {
  const { setPagesState ,chatbotDetails,DeleteChatbot} = useSharedState();
  const navigate=useNavigate();
  const chatbotDelete = async() => {
    setPagesState((prevState) => ({
      ...prevState,
      showdeletePage: false,
    }));
    await DeleteChatbot(chatbotDetails.chatbotId);
    navigate('/my-chatbots')
  };

  return (
    <>
        <div
          id="popup-modal"
          className="fixed top-10 right-0 left-0 z-50 flex justify-center  w-full h-full bg-black bg-opacity-80"
        >
          <div className="bg-white  relative rounded-lg flex flex-col justify-center mt-32  gap-2 shadow-lg w-11/12 md:w-1/3 p-2 h-1/4">
            <button
              className="absolute top-0 right-0 mt-1 mr-1  hover:bg-lime-800 hover:text-white text-lime-800 bg-white shadow-lg rounded-full p-2"
              onClick={() => {
                setPagesState((prevState) => ({
                  ...prevState,
                  showdeletePage: false,
                }));
              }}
            >
              <RxCross2 className="text-bold" />
            </button>
            <div className="flex gap-2 flex-row m-1">
              <span className="bg-red-200 w-10 h-10 flex justify-center items-center rounded-full p-1">
                <GoAlert className="text-xl  text-red-400 " />
              </span>
              <h2 className=" font-bold mt-2 text-gray-700">Delete ChatBot</h2>
            </div>
            <div className="flex flex-col justify-center ml-12 gap-2 ">
              <p className="text-sm m-1">
                Are you sure you want to delete your chatbot? This action cannot
                be undone.
              </p>

              <div className=" flex gap-4 justify-end mt-2 items-center">
                <button
                  className="py-1 px-2 text-sm w-[100px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out flex items-center justify-center gap-1.5"
                  onClick={() => {
                    setPagesState((prevState) => ({
                      ...prevState,
                      showdeletePage: false,
                    }));
                  }}
                >
                  <GiCancel className=" mt-0.5 text-md" />
                  Cancel
                </button>
                <button
                  className="py-1 px-2 text-sm w-[100px] rounded-md bg-red-600 text-white border-2 border-transparent hover:border-red-700 hover:bg-transparent hover:text-red-700 font-medium transition duration-200 ease-in-out flex justify-center items-center gap-1.5"
                  onClick={chatbotDelete}
                >
                  <MdDeleteOutline className=" mt-0.5 text-lg" />
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Delete;
