import { HoverEffect } from "../../Components/UI/card-hover-effect";
import { HiOutlineCubeTransparent } from "react-icons/hi2";
import { HiPaintBrush } from "react-icons/hi2";
import { MdPeople } from "react-icons/md";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { PiArrowsClockwiseFill } from "react-icons/pi";
import { HiOutlineSparkles } from "react-icons/hi2";
import { PiBookOpenTextDuotone } from "react-icons/pi";
import { CiChat1 } from "react-icons/ci";

export function CardHoverEffectDemo() {
  return (
    <div className="lg:w-5/6 w-full lg:mx-auto lg:px-10  flex flex-col ">
      <h3 className="text-3xl font-bold text-lime-700 px-4">Key Features</h3>
      <h6 className="text-lime-600 text-lg  font-semibold p-4 mb-5">Your no-code chatbot comes with many amazing features</h6>
      <HoverEffect items={features} />
    </div>
  );
}
export const features = [
  {
    title: "Multiple Data Sources",
    icon: <HiOutlineCubeTransparent />,
    description: "Train your chatbot with data from multiple sources including audio and video files"
  },
  {
    title:"Instant Chatbot",
    icon:<CiChat1/>,
    description:"Train your chatbot with data from multiple sources including audio and video files"
  },
  {
    title: "Customizations",
    icon: <HiPaintBrush />,
    description:
      "Customize your chatbot’s look and feel to match your brand’s style and website design."
  },
  
  {
    title: "Privacy & Security",
    icon: <IoShieldCheckmarkOutline />,
    description:
      "Your data is hosted on secure servers with robust encryption and access control"
  },
  {
    title: "Conversation Logs",
    icon: <PiBookOpenTextDuotone/>,
    description: "Export your chatbot conversations for analysis and inference"
  },
  {
    title: "Lead Generation",
    icon: <MdPeople/>
  ,
    description:
      "Gather leads and customer data for increased sales.",
  },
  {
    title: "Powerful AI Models",
    icon: <HiOutlineSparkles />,
    description:
      "Choose from a variety of AI models, including GPT-3.5-turbo and GPT-4.",
  },
  {
    title: "Auto-Retrain",
    icon: <PiArrowsClockwiseFill />,
    description:
      "Set your chatbot to retrain automatically and always be synced with your data"
  },
];