import React, { useState } from "react";
import AuthLayout from "./AuthLayout";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../Context/AuthenticationContext";
import { toast } from "react-toastify";

const SignUp = ({ validateEmail, validatepassword, passwordValidation }) => {
  const { UserSignUp, Loading,signUpDataRef } =
    useAuthState();
      const [SignUpcredentials, setSignUpcredentials] = useState({
    email: "",
    password: "",
    cpassword: "",
  });
  const navigate = useNavigate();

  const handleSignUpOnChange = (e) => {
    const { name, value } = e.target;
    setSignUpcredentials({
      ...SignUpcredentials,
      [name]: value,
    });
    if (name === "password") {
      validatepassword(value); // Validate password if it's not empty
    }
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateEmail(SignUpcredentials.email)) {
      toast.warn("Please enter a valid email address");
      return;
    }
    if (SignUpcredentials.password !== SignUpcredentials.cpassword) {
      toast.warn("Incorrect confirm password");
      return;
    }
    try {
      await UserSignUp(SignUpcredentials.email, SignUpcredentials.password);
      if (
        signUpDataRef.current?.responseCode === 200
      ) {
      
        navigate("/auth/login", { state: { showConfirmationMessage: true } });
      }
    } catch (error) {
      // Handle signup error
      console.error("Signup failed:", error);
      toast.error("Signup failed. Please try again.");
    }
  };
  return (
    <div>
      <AuthLayout
        heading={"Create a new account"}
        credentials={SignUpcredentials}
        handleOnChange={handleSignUpOnChange}
        passwordValidation={passwordValidation}
        handleSubmit={handleSignUp}
        actionText={"Sign Up"}
        check={"Already have an account?"}
        fromSignInPage={false}
        actionLink={"/auth/login"}
        validateEmail={validateEmail}
        Loading={Loading}
      />
    </div>
  );
};

export default SignUp;
