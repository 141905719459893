import React from 'react';
import { useDataSourcesState } from '../../Context/DataSourcesContext';

const TextSource = ({inCustomize}) => {
  const {ChatbotData, setChatbotData, SourceData, setSourceData} = useDataSourcesState();
  const {  Text } = SourceData;
  const {  AddedText } = ChatbotData;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    const charCountChange = newText.length - SourceData?.Text.length;
    
    setSourceData({
      ...SourceData,
      Text: newText,
      textchars: SourceData?.textchars + charCountChange,
    });
  };
  const handleAddedTextChange = (event) => {
    const newAddedText = event.target.value;
    const AddedcharCountChange = newAddedText.length - ChatbotData?.AddedText.length;
    
    setChatbotData({
      ...ChatbotData,
      AddedText: newAddedText,
      Addedtextchars: ChatbotData?.Addedtextchars + AddedcharCountChange,
    });
  };
  
  return (
    <div>
      <div className='my-6 mx-6 rounded-lg '>
        <textarea
          className='w-full p-2 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none border rounded-lg shadow-md text-sm'
          rows="15" cols="50"
          placeholder="Enter text here..."
          value={inCustomize ? AddedText : Text}
          onChange={inCustomize ? handleAddedTextChange : handleTextChange}
        ></textarea>
      </div>
    </div>
  );
};

export default TextSource;
