import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chatbot from "../../Components/ChatBot/Chatbot";
import { useSharedState } from "../../Context/SharedStateContext";
import SkeletonChatbotInfo from "../../Components/LoadingComponents/SkeletonChatbotInfo";

const Info = () => {
  const {
    customizeOptions,
    chatbotDetails,
    getChatbotDetails,
    loadingChatbotDetails,
    pagesState,
    selectedChatbot
  } = useSharedState();
  const navigate = useNavigate();
  const ChatUISettings = customizeOptions.ChatUI;
  const [localChatUISettings, setLocalChatUISettings] =
    useState(ChatUISettings);
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      // If the ID is not present, redirect to login page
      navigate("/auth/login");
    }
    if (selectedChatbot === null) {
      navigate("/my-chatbots");
    }
    getChatbotDetails(user);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chatbotDetails && chatbotDetails.chatbotUI) {
      setLocalChatUISettings((prevSettings) => {
        const initialMessages =
          chatbotDetails.chatbotUI.initialMessage ||
          prevSettings?.ChatUI?.initialMessages;
        const suggestedMessages =
          chatbotDetails.chatbotUI.suggestedMessages ||
          prevSettings?.ChatUI?.suggestedMessages;
        const initialMessagesArray = initialMessages
          ? initialMessages.split(/\r?\n/)
          : [];
        const suggestedMessagearray = suggestedMessages
          ? suggestedMessages.split(/\r?\n/)
          : [];

        return {
          ...prevSettings,
          initialMessages,
          suggestedMessages,
          textArray: initialMessagesArray.map((message) => ({
            text: message.trim(),
            role: "ai",
          })),
          suggestedMessagearray: suggestedMessagearray.map((message) =>
            message.trim()
          ), // Fixed this line
          placeholder:
            chatbotDetails.chatbotUI.messagePlaceholder.trim() ? chatbotDetails.chatbotUI.messagePlaceholder :
              prevSettings?.ChatUI?.placeholder === undefined ? '' : prevSettings?.ChatUI?.placeholder,
          Themes:
            chatbotDetails.chatbotUI.theme || prevSettings?.ChatUI?.Themes,
          name:
            chatbotDetails.chatbotUI.chatbotDisplayName.trim() !== ''
              ? chatbotDetails.chatbotUI.chatbotDisplayName
              : prevSettings?.ChatUI?.name === undefined ? '' : prevSettings?.ChatUI?.name,
          colorPicker:
            chatbotDetails.chatbotUI.userMessageColor ? chatbotDetails.chatbotUI.userMessageColor :
              prevSettings?.ChatUI?.colorPicker === undefined ? '' : prevSettings?.ChatUI?.colorPicker,
          bubblePicker:
            chatbotDetails.chatbotUI.chatBubbleButtonColor ? chatbotDetails.chatbotUI.chatBubbleButtonColor :
              prevSettings?.ChatUI?.bubblePicker === undefined ? '' : prevSettings?.ChatUI?.bubblePicker,
              TitleBarColor:
              chatbotDetails.chatbotUI.titleBarColor ? chatbotDetails.chatbotUI.titleBarColor :
                prevSettings?.ChatUI?.TitleBarColor === undefined ? '' : prevSettings?.ChatUI?.TitleBarColor,
          bubblePosition:
            chatbotDetails.chatbotUI.chatBubbleButtonAlignment ||
            prevSettings?.ChatUI?.bubblePosition,
            imagePreview:
            chatbotDetails.chatbotUI.chatbotProfilePic?chatbotDetails.chatbotUI.chatbotProfilePic:
            prevSettings?.ChatUI?.imagePreview===undefined?'':prevSettings?.ChatUI?.imagePreview,
          iconImage:
            chatbotDetails.chatbotUI.chatbotIcon?chatbotDetails.chatbotUI.chatbotIcon:
            prevSettings?.ChatUI?.iconImage===undefined?'':prevSettings?.ChatUI?.iconImage,
        };
      });
    }
  }, [chatbotDetails]);

  // console.log(chatbotDetails);
  if (Object.values(pagesState).some((value) => value === true)) {
    return (
      <div className='min-h-[80vh]'></div>
    )
  }
  if (loadingChatbotDetails) {
    return <SkeletonChatbotInfo />;
  }
  return (
    <div className=" w-11/12 md:w-5/6 border-[1px] border-lime-500/25 rounded-md bg-white flex flex-col gap-5  md:my-5 lg:my-10 my-12 mx-auto pb-5">
      <div className=" border-lime-500/25  border-b-[1px] justify-center py-3 ">
        <h3 className="text-xl font-semibold text-zinc-900 leading-6 mx-3">
          Chatbot Information
        </h3>
      </div>
      <div className="grid gap-10 md:grid-cols-2">
        <div className="ml-6 lg:mr-[60px] flex flex-col gap-5">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Model</p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.model}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Temperature</p>
              <p className="min-w-[100px] text-sm font-semibold text-zinc-700">
                {chatbotDetails.temperature}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">
                Number of files
              </p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.filesCount}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Language</p>
              <p className="min-w-[100px] text-sm font-semibold text-zinc-700">
                {chatbotDetails.language}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">
                Training Data Size
              </p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.charactersCount?.toLocaleString("en-IN")}{" "}
                Characters
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Status</p>
              <p className="min-w-[100px] text-sm font-semibold text-zinc-700">
                {chatbotDetails.status}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Visibility</p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.visibility}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">Created on</p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.createdDate}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-medium text-zinc-500">
                Last trained at
              </p>
              <p className="text-sm font-semibold text-zinc-700">
                {chatbotDetails.lastModifiedDate}
              </p>
            </div>
          </div>
        </div>
        <div className="right flex justify-center ">
          <Chatbot
            localChatUISettings={localChatUISettings}
            setLocalChatUISettings={setLocalChatUISettings}
            chatinterface={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
