import React from "react";
import logo from "../../Images/chatcellsLogo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
// import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="flex justify-around gap-10 bg-lime-500/25 h-96 border-t border-t-lime-700">
      <div class="container flex flex-col mx-auto items-center mt-10 gap-10 px-8 ">
        {/* <h1 className="text-4xl font-bold text-lime-700">Chatcells</h1> */}
        <img src={logo} alt="" width={180} />
        <div class="flex flex-col items-center gap-8 mb-5 justify-center">
          <div class="flex flex-wrap items-center justify-center gap-5 lg:gap-12 gap-y-3 lg:flex-nowrap text-dark-grey-900">
            <a href="/" class="text-gray-600 hover:text-gray-900">
              About
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Features
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Blog
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Resources
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Partners
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Help
            </a>
            <a href="/" class="text-gray-600 hover:text-gray-900">
              Terms
            </a>
          </div>
          <div class="flex items-center gap-8">
            <a href="www.facebook.com">
              <FaFacebookF className="text-2xl text-lime-700 hover:opacity-70"/>
            </a>
            <a href="www.twitter.com">
              <FaXTwitter className="text-2xl text-lime-700 hover:opacity-70"/>
            </a>
            {/* <a href="www.twitter.com">
              <FaGithub className="text-2xl text-lime-700 hover:opacity-70"/>
            </a> */}
            <a href="www.twitter.com">
              <FaLinkedinIn className="text-2xl text-lime-700 hover:opacity-70"/>
            </a>
            <a href="www.twitter.com">
              <FaInstagram className="text-2xl text-lime-700 hover:opacity-70"/>
            </a>
          </div>
        </div>
        <div class="flex items-center">
          <p class="text-base font-normal text-center text-grey-700">
            2024 chatcells technology. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;