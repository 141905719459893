import React from "react";
import { IoWarningOutline } from "react-icons/io5";

const ConfirmModal = ({
  title,
  file,
  handleCancelDelete,
  handleConfirmDelete,
}) => {
  return (
    <>
      <div className="modal z-10 bg-white p-8 rounded-lg" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0 flex items-center gap-2">
              <IoWarningOutline className="text-3xl text-orange-400"/>
              <h5 className="modal-title text-xl font-bold">{title}</h5>
              <button
                type="button"
                className="close absolute top-0 right-2 hover:opacity-50"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  className="text-3xl"
                  aria-hidden="true"
                  onClick={handleCancelDelete}
                >
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body border-0 my-3">
              <p className="text-zinc-600 text-sm">
                Are you sure you want to delete the file ?
              </p>
              <span className="text-zinc-600 font-semibold text-sm">
                {file} 
              </span>
              <p className="text-sm font-bold text-zinc-800 mt-2">
                {" "}
                Make sure to retrain your chatbot after deleting a source!
              </p>
            </div>
            <div className="modal-footer border-0 flex gap-7 justify-end mt-4">
              <button
                type="button"
                className="py-1 px-4 rounded-md border-2 border-transparent 
                    hover:border-lime-800 hover:text-lime-700 bg-lime-700 hover:bg-transparent text-white
                     font-semibold transition duration-200 ease-in-out flex justify-center items-center "
                data-dismiss="modal"
                onClick={handleCancelDelete}
              >
                Close
              </button>
              <button
                type="button"
                className="py-1 px-4 rounded-md border-2 border-transparent 
                hover:border-red-700 hover:text-red-700 bg-red-700 hover:bg-transparent text-white
                 font-semibold transition duration-200 ease-in-out flex justify-center items-center"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
