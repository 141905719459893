import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { BiSolidCustomize } from "react-icons/bi";
import { FaLink } from "react-icons/fa";
// import { FaUsersCog } from "react-icons/fa";
import { BsBodyText } from "react-icons/bs";
import { FaShareFromSquare } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { GoDatabase } from "react-icons/go";
import Integration from "../../../Pages/Dashboard_menu_pages/Integration";
import Delete from "../../../Pages/Dashboard_menu_pages/Delete";
import Share from "../../../Pages/Dashboard_menu_pages/Share";
import { useSharedState } from "../../../Context/SharedStateContext";

const DashBoard_Options = ({ setSelectedMenu }) => {
  const [Nav, setNav] = useState("info");
  const navigate = useNavigate();
  const location = useLocation();
  const {pagesState, setPagesState}=useSharedState()

  useEffect(() => {
    // Extract the menu from the pathname and update the Nav state
    const pathnameArray = location.pathname.split("/");
    let menu = null;

    for (let i = 0; i < pathnameArray.length; i++) {
      if (pathnameArray[i] === "dashboard" && i + 1 < pathnameArray.length) {
        menu = pathnameArray[i + 1];
        break;
      }
    }
    if (menu !== Nav) {
      setNav(menu);
      setSelectedMenu(menu);
    }
  }, [location.pathname, setSelectedMenu, Nav]);

  const handleMenuClick = (menu) => {
    setNav(menu);
    setSelectedMenu(menu);
    if (menu === "customize") {
      navigate(`/chatbot/dashboard/customize/general`);
    }
    else if(menu === "integration"){
      setPagesState(prevState => ({
        ...prevState,
        showIntegrationPage: !prevState.showIntegrationPage
      }));
    }
    else if(menu === "delete"){
      setPagesState(prevState => ({
        ...prevState,
        showdeletePage: !prevState.showdeletePage
      }));
    }
    else if(menu === "share"){
      setPagesState(prevState => ({
        ...prevState,
        showSharePage: !prevState.showSharePage
      }));
    }
     else {
      navigate(`/chatbot/dashboard/${menu}`);
    }
  };
  return (
    <>
      <div className="flex justify-center w-full">
        <nav className="w-4/5 mt-10 lg:mt-8 ">
          <ul className="flex flex-col gap-8 text-sm justify-center items-center text-zinc-500 font-semibold p-1 w-full mb-4">
            <button
              onClick={() => handleMenuClick("info")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full  ${
                Nav === "info"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700  "
              } p-1`}
            >
              <FaInfoCircle className={`text-2xl`} />
              Chatbot
            </button>
            <button
              onClick={() => handleMenuClick("customize")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "customize"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <BiSolidCustomize className="text-2xl" />
              Customize
            </button>
            <button
              onClick={() => handleMenuClick("datasources")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "datasources"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <GoDatabase className="text-2xl" />
              Data Sources
            </button>
            <button
              onClick={() => {
                handleMenuClick("integration");
              }}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "integration"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <FaLink className="text-2xl" />
              Website Integration
            </button>
            {/* <button
              onClick={() => handleMenuClick("leads")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "leads"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <FaUsersCog className="text-2xl" />
              Leads
            </button> */}
            <button
              onClick={() => handleMenuClick("logs")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "logs"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <BsBodyText className="text-2xl" />
              Logs
            </button>
            <button
              onClick={() => handleMenuClick("share")}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "share"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <FaShareFromSquare className="text-2xl" />
              Share
            </button>
            <button
              onClick={() => {
                handleMenuClick("delete")
              }}
              className={`hover:text-lime-900 hover:shadow-xl transition duration-200 ease-in-out flex flex-col justify-center items-center gap-1 w-full ${
                Nav === "delete"
                  ? " text-lime-900 border-b-2 border-b-lime-800 shadow-xl"
                  : " text-lime-700 "
              } p-1`}
            >
              <MdDelete className="text-2xl" />
              Delete
            </button>
          </ul>
        </nav>
      </div>
      {pagesState.showIntegrationPage && (
        <Integration
          showIntegrationPage={pagesState.showIntegrationPage}
        />
      )}
      {pagesState.showSharePage && (
        <Share
          showSharePage={pagesState.showSharePage}
        />
      )}
      {pagesState.showdeletePage && (
        <Delete
          showdeletePage={pagesState.showdeletePage}
        />
      )}
    </>
  );
};

export default DashBoard_Options;
