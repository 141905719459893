import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../Components/UI/PasswordInput";
import Button from "../../Components/UI/Button";
import { toast } from "react-toastify";
import config from '../../../src/config'
import { useAuthState } from "../../Context/AuthenticationContext";

const ResetPassword = () => {
  const [resetCredentials, setresetCredentials] = useState({
    newPassword: "",
    cNewpassword: "",
  });
  const {Loading,setLoading}=useAuthState();
  const navigate = useNavigate();
  const dyanamicbackendUrl= config[process.env.REACT_APP_Running_Mode].backendUrl;

  const [passwordValidation, setpasswordValidation] = useState("");

  const validatepassword = (password) => {
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
    if (passwordPattern.test(password)) {
      setpasswordValidation(""); // Email is valid, clear any previous validation message
    } else {
      setpasswordValidation(
        "Password length should be 8 and must contain one uppercase, one number and one symbol"
      );
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setresetCredentials({
      ...resetCredentials,
      [name]: value,
    });
    if (name === "newPassword") {
      if (value.trim().length === 0) {
        setpasswordValidation(""); // Clear validation message if password is empty
      } else {
        validatepassword(value); // Validate password if it's not empty
      }
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (resetCredentials.newPassword === resetCredentials.cNewpassword) {
      try {
        const response = await fetch(
          `${dyanamicbackendUrl}userpasswordupdate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              emailId: 'example@example.com',
              password: resetCredentials.newPassword,
            }),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          toast.success("Successfully Updated the password");
          navigate("/auth/login");
        } else if (data.responseCode === 404) {
          toast.error("Email Id not found");
        }
      } catch (error) {
        console.error(error.message);
      }
      finally{
        setresetCredentials({
            newPassword: "",
            cNewpassword: ""
        })
        setLoading(false);
      }
    } else {
      toast.warn("Incorrect confirm password");
    }
  };
  return (
    <div>
          <div className=" flex justify-center items-center mt-16">
      <form
        onSubmit={handleUpdate}
        className="w-11/12 my-10 border border-lime-500/25 rounded-lg"
      >
        <div className=" px-12 py-5 rounded-lg">
          <div className="flex flex-col gap-6">
            <div className={`flex flex-col gap-2 items-center`}>
              <h1
                className={`text-lg sm:text-2xl font-semibold flex items-center gap-2`}
              >
                Create a new password
              </h1>
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <PasswordInput
                  label="Password"
                  name="newPassword"
                  placeholder="Password"
                  value={resetCredentials.newPassword}
                  passwordValidation={passwordValidation}
                  handleOnChange={handleOnChange}
                />
              </div>
              <PasswordInput
                label="Confirm Password"
                name="cNewpassword"
                placeholder="Confirm Password"
                value={resetCredentials.cNewpassword}
                handleOnChange={handleOnChange}
              />
              <Button className={`my-4 w-full text-lg`} title={"Save"} type="submit" Loading={Loading} LoadingTitle='Please Wait...'/>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default ResetPassword;
