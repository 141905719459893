import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useSharedState } from "../../Context/SharedStateContext";

const Integration = () => {
  const [copyIframestatus, setcopyIframestatus] = useState(false);
  const [copyScriptstatus, setcopyScriptstatus] = useState(false);
  const { setPagesState, customizeOptions, selectedChatbot } = useSharedState();
  const ChatUISettings = customizeOptions.ChatUI;

  const copyContent = (id) => {
    const element = document.getElementById(id);
    const tempInput = document.createElement("textarea");
    tempInput.value = element.textContent;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    switch (id) {
      case "copyIframe":
        setcopyIframestatus((prev) => !prev);
        setTimeout(() => {
          setcopyIframestatus((prev) => !prev);
        }, 2000);
        break;
      case "copyScript":
        setcopyScriptstatus((prev) => !prev);
        setTimeout(() => {
          setcopyScriptstatus((prev) => !prev);
        }, 2000);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div
        id="popup-modal"
        className="fixed top-12 right-0  left-0 z-50 flex justify-center items-center w-screen h-full bg-black bg-opacity-80 overflow-y-auto"
      >
        <div className="bg-white mt-[-50px] overflow-y-auto relative rounded-lg flex flex-col justify-center items-center gap-2 shadow-lg w-11/12 md:w-[60%] p-4">
          <button
            className="absolute top-0 right-0 mt-1 mr-1 z-50  hover:bg-lime-800 hover:text-white text-lime-800 bg-white shadow-lg rounded-full p-2"
            onClick={() => {
              setPagesState((prevState) => ({
                ...prevState,
                showIntegrationPage: false,
              }));
            }}
          >
            <RxCross2 className="text-bold" />
          </button>
          <h3 className="text-sm font-bold text-gray-700">www.chatcells.com</h3>
          <div className="flex flex-col gap-2 w-11/12">
            <p className="text-sm m-1">
              To add chatbot anywhere on your website, add this iframe to your
              HTML code
            </p>
            <div id="copyIframe" className="rounded-md bg-zinc-100 p-1">
              <pre className="mx-1 text-xs sm:text-sm">
                <code className="overflow-y-auto w-full sm:w-auto whitespace-pre-wrap ">
                  {`<iframe    id="chatcells.ai" src="https://chatcells.ai/api/frame-assistant/${selectedChatbot?.chatbotId}"\n  title="${ChatUISettings.name}"  width="100%"\n style="height: 100vh;"\n  frameBorder="0"\n></iframe>`}
                </code>
              </pre>
            </div>
            <div className="w-full flex justify-center mt-2 items-center">
              <button
                className="py-1 px-2 text-sm w-[150px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out"
                onClick={() => copyContent("copyIframe")}
              >
                Copy Iframe{" "}
                {!copyIframestatus ? (
                  <FaCopy className="inline ml-1 text-md" />
                ) : (
                  <TiTick className="inline ml-1 text-md bg-lime-500/25" />
                )}
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-11/12">
            <p className="text-sm m-1">
              To add chatbot anywhere on your website, add this script to your
              HTML code
            </p>
            <div id="copyScript" className="rounded-md bg-zinc-100 p-1">
              <pre className="mx-1 text-xs sm:text-sm">
                <code className="whitespace-pre-wrap">
                  {`<script>
  window.embeddedChatbotConfig = {
    chatbotId: "${selectedChatbot?.chatbotId}"
  }
</script>
<script src="https://chatcells.ai/chatbot.js" 
   defer></script>`}
                </code>
              </pre>
            </div>
            <div className="w-full flex justify-center mt-2 items-center">
              <button
                className="py-1 px-2 text-sm w-[150px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out"
                onClick={() => copyContent("copyScript")}
              >
                Copy Script{" "}
                {!copyScriptstatus ? (
                  <FaCopy className="inline ml-1 text-md" />
                ) : (
                  <TiTick className="inline ml-1 text-md bg-lime-500/25" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integration;
