import { createContext, useContext, useRef, useState } from "react";
import { toast } from "../Components/UI/NotificationManager";
import config from "../../src/config";

const AuthenticationContext = createContext();

export const AuthStateProvider = ({ children }) => {
  const [signUpData, setSignUpData] = useState({});
  const [sendCodeData, setSendCodeData] = useState({});
  const [logInData, setLogInData] = useState({});
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [CustomerId, setCustomerId] = useState(null);
  const [userEmailId, setuserEmailId] = useState("");
  const [Loading, setLoading] = useState(false);
  const logInDataRef = useRef(null);
  const signUpDataRef = useRef(null);
  const dyanamicbackendUrl = config[process.env.REACT_APP_Running_Mode].backendUrl;
  const UserSignUp = async (email, password) => {
    setLoading(true);
    try {
      const url = `${dyanamicbackendUrl}newuser`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: email,
          password: password,
        }),
      });
      const data = await response.json();
      setSignUpData(data);
      signUpDataRef.current = data;
      if (data?.responseCode === 200) {
        toast.success("Successfully created");
        // SendConfirmationMail(email);
      } else if (data?.responseCode === 500) {
        toast.error("An error has occurred. We will be back soon.");
      }
      else if (data?.responseCode === 400) {
        if (data?.response === 'Email Id already exists')
          toast.warn("Email Id already exists");
        else if (data?.response === 'Invalid Email')
          toast.error("Invalid Email");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const SendConfirmationMail = async (email, purpose) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}sendcode`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            emailId: email,
            purpose: purpose,
          }),
        }
      );
      const data = await response.json();
      setSendCodeData(data);
      if (data.response.statusCode === 200) {
        // navigate("/auth/login");
        toast.success("Link sent");
      } else if (data.responseCode === 400) {
        toast.error("Email Id not found");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const UserLogin = async (email, password) => {
    setLoading(true);
    try {
      const url = `${dyanamicbackendUrl}validateuser`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: email,
          password: password,
        }),
      });
      const data = await response.json();
      logInDataRef.current = data;
      setLogInData(data);
      if (data.responseCode === 200) {
        setCustomerId(data.customerId);
        localStorage.setItem("user", data.customerId);
        localStorage.setItem("email", email);
        setuserEmailId(email);
        setisLoggedIn(true);
        toast.success("Successfully logged in");
      } else if (data.responseCode === 404) {
        toast.error("Email Id not found");
      } else if (data.responseCode === 400) {
        toast.warn("User Activation Pending");
        // SendConfirmationMail(email);
      } 
      else if(data.responseCode===401){
      toast.error("Password does not match");
      }
      else {
        toast.error("Invalid credentials");
      }
    } catch (error) {
      toast.error("Internal Error");
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const ResetPassword = async (newPassword) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}userpasswordupdate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            emailId: "example@example.com",
            password: newPassword,
          }),
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        toast.success("Successfully Updated the password");
        // navigate("/auth/login");
      } else if (data.responseCode === 404) {
        toast.error("Email Id not found");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        UserLogin,
        UserSignUp,
        SendConfirmationMail,
        ResetPassword,
        Loading,
        setLoading,
        isLoggedIn,
        CustomerId,
        logInData,
        signUpData,
        sendCodeData,
        userEmailId,
        setuserEmailId,
        logInDataRef,
        signUpDataRef,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthState = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error("useAuthState must be used within a AuthStateProvider");
  }
  return context;
};
