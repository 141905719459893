import React, { useEffect, useState } from "react";
import Button from "../UI/Button";
import { useDataSourcesState } from "../../Context/DataSourcesContext";
import { toast } from "react-toastify";

const WebsiteSource = ({ inCustomize }) => {
  const {
    SourceData,
    setSourceData,
    progress,
    WebsiteformData,
    setWebsiteFormData,
    loading,
    handleFetchLinks,
    ChatbotData,
    setChatbotData,
    UpdateChatbotloading,
    CreateChatbotloading,
    isBaseUrl
  } = useDataSourcesState();
  const { LinkList } = SourceData;
  const { AddedLinkList } = ChatbotData;
  const [isValidCrawlUrl, setIsValidCrawlUrl] = useState(true);
  const [isValidSubmitUrl, setIsValidSubmitUrl] = useState(true);
  const [isValidIncludeLink, setIsValidIncludeLink] = useState(true);
  const handleUrlChange = (e, setUrl, setIsValidUrl) => {
    const { name, value } = e.target;
    setUrl((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Regular expression for basic URL validation
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    // Check if the URL is valid (or empty)
    setIsValidUrl(value === "" || urlPattern.test(value));
  };

  const handleAddLink = () => {
    const isUrlAlreadyPresent = LinkList.some(
      (link) => link.url === WebsiteformData?.includeLink
    );
    if (isUrlAlreadyPresent) {
      toast.warn("The url is already included");
      return;
    }
    if (isValidIncludeLink) {
      const newLink = { url: WebsiteformData?.includeLink, count: 0 };
      setSourceData({
        ...SourceData,
        LinkList: [newLink, ...SourceData.LinkList],
      });
      setWebsiteFormData((prevState) => ({
        ...prevState,
        includeLink: "",
      }));
    }
  };

  const handleRemoveLink = (index) => {
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      LinkList: prevSourceData.LinkList.filter((_, i) => i !== index),
    }));
  };
  const handleRemoveAddedLink = (index, link) => {
    const removedLinkCount = link.count;
    setChatbotData((prevChatbotData) => ({
      ...prevChatbotData,
      AddedLinkList: prevChatbotData.AddedLinkList.filter(
        (_, i) => i !== index
      ),
      Addedlinkchars: prevChatbotData.Addedlinkchars - removedLinkCount,
    }));
  };
  const handleRemoveAllLinks = () => {
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      LinkList: [],
    }));
  };
  const handleRemoveAllAddedLinks = () => {
    setChatbotData((prevChatbotData) => ({
      ...prevChatbotData,
      AddedLinkList: [],
    }));
  };
  useEffect(() => {
    const counts = LinkList?.map((item) => parseInt(item.count ?? 0)); // Parse counts as integers, defaulting to 0 if count is undefined or null
    const totalChars = counts?.reduce((acc, curr) => acc + curr, 0);
    setSourceData((prevSourceData) => ({
      ...prevSourceData,
      linkchars: totalChars,
    }));
  }, [setSourceData, LinkList]);

  return (
    <div className="my-5 mx-5 ">
      {/* Crawl Section */}
      <div>
        <label htmlFor="CrawlUrl" className="text-gray-600 text-sm">
          Crawl
        </label>
        <div className="flex flex-col md:flex-row w-full my-2 gap-2  ">
          <input
            type="text"
            name="crawlUrl"
            value={WebsiteformData?.crawlUrl}
            onChange={(e) =>
              handleUrlChange(e, setWebsiteFormData, setIsValidCrawlUrl)
            }
            placeholder="https://www.example.com"
            className={`rounded-md p-2 border ${
              LinkList.length > 0 ? " w-full md:w-2/3 " : " w-full md:w-3/4 "
            } focus:outline-none ${
              isValidCrawlUrl
                ? "border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                : "border-red-500 "
            } text-sm shadow-md`}
          />
          <Button
            title={LinkList.length > 0 ? "Fetch more links" : "Fetch Links"}
            Loading={loading}
            className={" w-full md:w-fit "}
            handleOnClick={() => {
              const user = localStorage.getItem("user");
              handleFetchLinks(user);
            }}
            type="button"
            {...(isBaseUrl ? { LoadingTitle: 'fetching...' } : {})}
            disabled={!isValidCrawlUrl || !WebsiteformData?.crawlUrl || isBaseUrl}
          />
        </div>
        {!isValidCrawlUrl && WebsiteformData?.crawlUrl !== "" && (
          <p className="text-red-400 flex justify-start w-4/5">Invalid Url</p>
        )}
        {progress >= 0 && progress <= 100 && isBaseUrl && (
          <div className="relative w-full mt-1 mb-6">
            {loading && (
              <>
                <span className="text-lime-700 text-xs">{progress}%</span>
                <div className="overflow-hidden h-2 mt-1 text-xs flex rounded bg-lime-100">
                  <div
                    style={{ width: `${progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-lime-500 "
                  ></div>
                </div>
                {progress >= 40 && (
                  <p className="text-red-500 text-xs font-medium mt-1 float-right">
                    process is taking longer than expected due to many links.
                  </p>
                )}
              </>
            )}
          </div>
        )}
        <p className="my-5 text-gray-500 text-xs sm:text-sm">
          This will crawl all the links starting with the URL (not including
          files on the website).
        </p>
      </div>
      <div className="flex items-center justify-center mt-6 mx-2">
        <span className="w-1/2 mr-2 h-px bg-lime-500/25 "></span>
        <p className=" opacity-70 text-gray-800">OR</p>
        <span className=" w-1/2 ml-2 h-px bg-lime-500/25 "></span>
      </div>
      {/* Submit Sitemap Section */}
      <div className="my-3">
        <label htmlFor="SubmitUrl" className="text-gray-600 text-sm">
          Submit Sitemap
        </label>
        <div className="flex flex-col md:flex-row w-full my-2 gap-2">
          <input
            type="text"
            name="submitUrl"
            value={WebsiteformData?.submitUrl}
            onChange={(e) =>
              handleUrlChange(e, setWebsiteFormData, setIsValidSubmitUrl)
            }
            placeholder="https://www.example.com/sitemap.xml"
            className={`rounded-md p-2 ${
              LinkList.length > 0 ? " w-full md:w-3/4 " : " w-full md:w-3/4 "
            } border focus:outline-none ${
              isValidSubmitUrl
                ? "border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                : "border-red-500 "
            } text-sm shadow-md`}
          />
          <Button
            title={LinkList.length > 0 ? "Load SiteMap" : "Load SiteMap"}
            Loading={loading}
            handleOnClick={() => {
              const user = localStorage.getItem("user");
              handleFetchLinks(user);
            }}
            type="button"
            {...(!isBaseUrl ? { LoadingTitle: 'fetching...' } : {})}
            disabled={!isValidSubmitUrl || !WebsiteformData?.submitUrl}
          />
        </div>
        {!isValidSubmitUrl && WebsiteformData?.submitUrl !== "" && (
          <p className="text-red-400 flex justify-start w-4/5">Invalid Url</p>
        )}
        {progress >= 0 &&  progress <= 100 && !isBaseUrl &&(
          <div className="relative w-full mt-1 mb-6">
            {loading && (
              <>
                <span className="text-lime-700 text-xs">{progress}%</span>
                <div className="overflow-hidden h-2 mt-1 text-xs flex rounded bg-lime-100">
                  <div
                    style={{ width: `${progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-lime-500 "
                  ></div>
                </div>
                {progress >= 40 && (
                  <p className="text-red-500 text-xs font-medium mt-1 float-right">
                    process is taking longer than expected due to many links.
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center mt-6 mx-2">
        <span className="w-1/4 sm:w-1/3 md:w-1/2  h-px bg-lime-500/25 "></span>
        <p className=" opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base">
          Include Links
        </p>
        <span className="w-1/4 sm:w-1/3 md:w-1/2  h-px bg-lime-500/25 "></span>
      </div>
      {/* Include Links Section */}
      <div className="flex flex-col gap-3">
        <div className="flex w-full mt-6 gap-2">
          <input
            type="text"
            name="includeLink"
            value={WebsiteformData?.includeLink}
            placeholder="https://www.example.com"
            onChange={(e) =>
              handleUrlChange(e, setWebsiteFormData, setIsValidIncludeLink)
            }
            className={`rounded-md py-1.5 px-2 w-full border focus:outline-none ${
              isValidIncludeLink
                ? "border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                : "border-red-500 "
            } text-sm`}
          />
          <Button
            title="Add"
            Loading={loading}
            handleOnClick={handleAddLink}
            type="button"
            disabled={!isValidIncludeLink || !WebsiteformData?.includeLink}
          />
        </div>
        {!isValidIncludeLink && WebsiteformData?.includeLink !== "" && (
          <p className="text-red-400 flex justify-start w-4/5 text-sm">
            Invalid Url
          </p>
        )}
      </div>
      {/* Display Links Section */}
      {AddedLinkList && inCustomize && (
        <>
          <div className="flex items-center justify-center mt-6 mx-2">
            <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
            <p className="opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
              Trained Links
            </p>
            <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
          </div>
          <div className="mb-4 mt-5 max-h-96 overflow-scroll">
            <button
              onClick={handleRemoveAllAddedLinks}
              className={`${
                AddedLinkList?.length < 2 ? " hidden " : " "
              } bg-none text-red-500  border-none hover:opacity-70`}
              disabled={UpdateChatbotloading || CreateChatbotloading}
            >
              Remove all
            </button>
            {AddedLinkList?.map((link, index) => (
              <div
                key={index}
                className="flex w-full mt-2 gap-2.5 items-center "
              >
                <input
                  type="text"
                  name={`link-${index}`}
                  value={link.url}
                  readOnly
                  className={`rounded-md bg-gray-100 py-1.5 px-2 w-5/6 border focus:outline-none  ${
                    isValidIncludeLink
                      ? "border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                      : "border-red-500 "
                  } text-sm`}
                />
                <span className="text-xs text-zinc-500 w-10">
                  {link?.count?.toLocaleString("en-IN")}
                </span>
                <button
                  className={`text-red-400 px-2 py-0.5 text-sm hover:opacity-70`}
                  onClick={() => handleRemoveAddedLink(index, link)}
                  disabled={UpdateChatbotloading || CreateChatbotloading}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            ))}
          </div>
        </>
      )}
      {LinkList && (inCustomize ? LinkList.length > 0 : true) && (
        <>
          <div className="flex items-center justify-center mt-6 mx-2">
            <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
            <p className="opacity-70 w-1/3 sm:w-1/4 md:w-1/3 lg:w-1/4 flex justify-center text-gray-800 text-sm sm:text-base font-semibold">
              Fetched Links
            </p>
            <span className="w-1/4 sm:w-1/3 md:w-1/2 h-px bg-lime-500/25"></span>
          </div>
          <div className="mb-4 mt-5 max-h-96 overflow-scroll">
            <button
              onClick={handleRemoveAllLinks}
              className={`${
                LinkList?.length < 2 ? " hidden " : " "
              } bg-none text-red-500  border-none hover:opacity-70`}
              disabled={UpdateChatbotloading || CreateChatbotloading}
            >
              Remove all
            </button>
            {LinkList?.map((link, index) => (
              <div
                key={index}
                className="flex w-full mt-2 gap-2.5 items-center "
              >
                <input
                  type="text"
                  name={`link-${index}`}
                  value={link.url}
                  readOnly
                  className={`rounded-md bg-gray-100 py-1.5 px-2 w-5/6 border focus:outline-none  ${
                    isValidIncludeLink
                      ? "border-lime-500/25 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                      : "border-red-500 "
                  } text-sm`}
                />
                <span className="text-xs text-zinc-500 w-10">
                  {link?.count?.toLocaleString("en-IN")}
                </span>
                <button
                  className={`text-red-400 px-2 py-0.5 text-sm hover:opacity-70`}
                  onClick={() => handleRemoveLink(index)}
                  disabled={UpdateChatbotloading || CreateChatbotloading}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WebsiteSource;
