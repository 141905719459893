import React from "react";

const SkeletonLogs = () => {
  return (
    <div className="w-full flex justify-center mt-10">
      <div className="flex flex-col gap-10 border border-lime-500/25 w-5/6 justify-center rounded-md ">
        <div className="bg-lime-50 h-16 border-b border-b-lime-500/25 duration-300 ease-in-out animate-pulse transition-all rounded-md"></div>
        <div className="h-[500px] flex flex-col gap-10">
          <div className="bg-lime-50 h-16 duration-300 ease-in-out animate-pulse transition-all mx-4"></div>
          <div className=" h-48 mb-16 flex gap-10 justify-center px-4">
            <div className="bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-40 w-1/3"></div>
            <div className="bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-96 w-2/3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLogs;
