import {  useAnimation  } from "framer-motion";
import React, { useEffect, useState } from "react";
import { GoogleGeminiEffect } from "../../Components/UI/google-gemini-effects";

export function GoogleGeminiEffectDemo() {
  const ref = React.useRef(null);
  const controls = useAnimation();
  const [isHalfway, setIsHalfway] = useState(false);

  useEffect(() => {
    const animatePath = async () => {
      await controls.start({
        pathLength: [0, 0.5, 1], // An array of values to animate between
        onUpdate: (latest) => {
          console.log("lates", latest)
          if (latest.pathLength >= 0.5) {
            setIsHalfway(true);
          } else {
            setIsHalfway(false);
          }
        },
        transition: {
          duration: 6, // Adjust duration as needed
          repeat: Infinity, // Repeat infinitely
          repeatType: 'loop', // Restart the animation smoothly
        }
      })
    };

    animatePath();
    return () => controls.stop(); // Stop the animation on unmount
  }, [controls, isHalfway]);
  
  return (
    <>
    <div
      className="h-[85vh] bg- w-full dark:border dark:border-white/[0.1]  rounded-md relative pt-0 overflow-clip"
      ref={ref}
    >
      <GoogleGeminiEffect
        isInRange={isHalfway}
        controls={controls}
        title="How it works!"
        description="Simply add your data sources, train the model, integrate the fully customizable chatbot with your website and leverage all the benefits"
        className="flex flex-col gap-10 items-center justify-center w-full"
      />
    </div>
    </>
  );
}