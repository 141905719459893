import React, { useState } from "react";
import { CiFileOn } from "react-icons/ci";
import { LuText } from "react-icons/lu";
import { FaGlobeAmericas } from "react-icons/fa";
// import { IoChatbubblesOutline } from "react-icons/io5";
import { RiFolderVideoLine } from "react-icons/ri";
const DataInput_Options = ({ setSource, inCustomize }) => {
  const [nav, setNav] = useState("Files");

  const handleNavClick = (section) => {
    setNav(section);
    setSource(section);
  };
  return (
    <div
      className={`flex items-center lg:mt-0 h-full ${
        inCustomize
          ? "w-[87%] sm:w-[95%] mt-0 lg:mt-2 border-b-2 bg-lime-500/25 rounded-xl border-b-lime-500/25 py-1 sm:py-2.5 px-3"
          : " justify-center mt-2"
      }`}
    >
      <div
        className={` flex lg:flex-col  overflow-y-auto ${
          inCustomize ? " w-full lg:w-1/2  " : "w-full py-3 px-3 lg:mx-2"
        }   rounded  h-full`}
      >
        <nav className={`bg-transparent lg:text-base`}>
          <ul className={`lg:mx-3 flex flex-1 lg:flex-col`}>
            <li>
              <ul
                className={`gap-3 sm:gap-4 flex ${
                  inCustomize ? "flex-row " : " xl:flex-col "
                } font-semibold text-xs`}
              >
                <button
                  className={`flex flex-col sm:flex-row rounded-md ${
                    nav === "Files"
                      ? "bg-lime-700 text-white shadow-lg"
                      : " text-lime-900"
                  } hover:bg-transparent hover:text-lime-900 sm:py-1.5 py-1 gap- sm:gap-2 items-center border-2 border-transparent px-2 sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg`}
                  onClick={() => handleNavClick("Files")}
                >
                  <CiFileOn className={`text-base sm:text-lg md:text-xl`} />
                  Files
                </button>
                <button
                  className={`flex flex-col sm:flex-row 
                   rounded-md ${
                    nav === "Media"
                      ? "bg-lime-700 text-white shadow-lg"
                      : " text-lime-900"
                  } hover:bg-transparent hover:text-lime-900 sm:py-1.5 py-1 gap- sm:gap-2 items-center border-2 border-transparent px-2 sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg`}
                  onClick={() => handleNavClick("Media")}
                >
                  <RiFolderVideoLine className={`text-base sm:text-lg md:text-xl`} />
                  Media
                </button>
                <button
                  className={`flex flex-col sm:flex-row rounded-md ${
                    nav === "Text"
                      ? "bg-lime-700 text-white shadow-lg"
                      : " text-lime-900"
                  } hover:bg-transparent hover:text-lime-900 sm:py-1.5 py-1 gap- sm:gap-2 items-center border-2 border-transparent px-2 sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg`}
                  onClick={() => handleNavClick("Text")}
                >
                  <LuText className={`text-base sm:text-lg md:text-xl`} />
                  Text
                </button>
                <button
                  className={`flex flex-col sm:flex-row rounded-md ${
                    nav === "Website"
                      ? "bg-lime-700 text-white shadow-lg"
                      : " text-lime-900"
                  } hover:bg-transparent hover:text-lime-900 sm:py-1.5  py-1 gap- sm:gap-2 items-center border-2 border-transparent px-2 sm:px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg`}
                  onClick={() => handleNavClick("Website")}
                >
                  <FaGlobeAmericas className={`text-base sm:text-lg md:text-xl`} />
                  Website
                </button>
                {/* <button
                    className={`flex rounded-md ${
                      nav === "Q&A"
                        ? "bg-lime-700 text-white shadow-lg" : " text-lime-900"
                      } hover:bg-transparent hover:text-lime-900  py-2 gap-2 items-center border-2 border-transparent px-4 hover:border-lime-700 transition duration-200 ease-in-out hover:shadow-lg`}
                    onClick={() => handleNavClick("Q&A")}
                  >
                    <IoChatbubblesOutline  className={`text-xl`}/>
                   Q&A
                  </button> */}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DataInput_Options;
