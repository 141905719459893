import React from 'react'

const SkeletonChatbotInfo = () => {
  return (
    <div className=" border-[1px] border-lime-500/25 rounded-md bg-white flex flex-col gap-5  my-10 mx-auto pb-5 duration-300 ease-in-out animate-pulse transition-all h-full w-4/5">
      <div className=" border-lime-500/25  border-b-[1px] justify-center py-3 duration-300 ease-in-out animate-pulse transition-all bg-lime-50 h-16 ">
        
      </div>
      <div className="grid gap-10 sm:grid-cols-2 duration-300 ease-in-out animate-pulse transition-all h-full px-10">
        <div className="flex flex-col gap-5  duration-300 ease-in-out animate-pulse transition-all h-full">
          <div className='bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-10 rounded-md'></div>
          <div className='bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-10 rounded-md'></div>
          <div className='bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-10 rounded-md'></div>
          <div className='bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-10 rounded-md'></div>
          <div className='bg-lime-50 duration-300 ease-in-out animate-pulse transition-all h-10 rounded-md'></div>
        </div>
        <div className="right duration-300 ease-in-out animate-pulse transition-all bg-lime-50 h-full rounded-md ">
          
        </div>
      </div>
    </div>
  )
}

export default SkeletonChatbotInfo
