import React, { useEffect, useState } from "react";
import BoxLayout from "./BoxLayout";
import { useSharedState } from "../../../Context/SharedStateContext";
import { useDataSourcesState } from "../../../Context/DataSourcesContext";
import BotPersonality from "../../../Pages/BotPersonality";

const DynamicComponent = ({ localModelSettings, setLocalModelSettings }) => {
  const { customPersonality, selectedPersonality } = useDataSourcesState();
  // const [language, setLanguage] = useState("English");
  const [openBotPersonality, setOpenBotPersonality] = useState(false);
  const handleOpenModel = async () => {
      setOpenBotPersonality(true);
  };
  const handleLanguageChange = (event) => {
    setLocalModelSettings((prevSettings) => ({
      ...prevSettings,
      Language: event.target.value,
    }));
  };
  const handlePromptChange = (event) => {
    setLocalModelSettings((prevSettings) => ({
      ...prevSettings,
      Prompt: event.target.value,
    }));
  };
  const handleModelChange = (event) => {
    setLocalModelSettings((prevSettings) => ({
      ...prevSettings,
      model: event.target.value,
    }));
  };
  const handleTemperatureChange = (event) => {
    setLocalModelSettings((prevSettings) => ({
      ...prevSettings,
      Temperature: event.target.value,
    }));
  };
  function convertDateFormat(dateString) {
    // Create a Date object from the provided dateString
    const date = new Date(dateString);

    // Get the month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];

    // Get the day, year, hours, and minutes
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the date in the desired form
    const formattedDate = `${month} ${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;

    return formattedDate;
  }

  return (
    <div className="py-4 px-4 flex flex-col gap-7 ">
      <div className="flex flex-col gap-2 mt-2 justify-center ">
        <label htmlFor="model" className="text-sm text-zinc-800 font-semibold">
          Model
        </label>
        <select
          id="model"
          name="model"
          value={localModelSettings.model}
          onChange={handleModelChange}
          className="py-2 px-4 rounded-md w-full text-sm font-zinc-700 font-medium border-lime-500/25 border focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-800"
        >
          <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
          <option value="gpt-4-turbo">gpt-4-turbo</option>
          <option value="gpt-4">gpt-4</option>
        </select>
        <p className="text-xs text-zinc-500 font-medium">
          gpt-4 and gpt-4-turbo are much better at following the instructions
          and not hallucinating, but they're slower and more expensive than
          gpt-3.5-turbo (1 message using gpt-3.5-turbo costs 1 message credit. 1
          message using gpt-4-turbo costs 10 message credits. 1 message using
          gpt-4 costs 20 message credits)
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="prompt"
          className="text-sm text-zinc-800 font-medium mb-1"
        >
          Prompt
        </label>
        <textarea
          type="text"
          rows={4}
          value={localModelSettings.Prompt}
          onChange={handlePromptChange}
          className="border border-lime-500/25 py-2 px-4 rounded-md  focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none text-zinc-800 text-sm"
        />
        <p className="text-xs text-zinc-500 font-medium">
          The instructions allows you to customize your chatbot's personality
          and style. Please make sure to experiment with the instructions by
          making it very specific to your data and use case.
        </p>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <h4 className="font-semibold text-sm">ChatBot Personality</h4>
        <div className="w-full flex gap-2 items-center  ">
          <h6 className="text-sm text-zinc-600 ">
            {customPersonality ? customPersonality : selectedPersonality?.name}
          </h6>
          <button
            className="text-sm font-semibold text-lime-500 hover:text-lime-700 bg-none border-none"
            onClick={handleOpenModel}
          >
            (change)
          </button>
        </div>
      </div>
      <div className=" flex-col gap-3 justify-center hidden">
        <label htmlFor="language" className="font-semibold text-sm w-full">
          Choose language
        </label>
        <select
          id="language"
          name="language"
          value={localModelSettings.Language}
          onChange={handleLanguageChange}
          className="py-1.5 px-4 rounded-md w-1/3 text-sm font-zinc-700 font-medium focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none border border-lime-500/25"
        >
          <option value="English">English</option>
          <option value="German">German</option>
          <option value="French">French</option>
          <option value="Spanish">Spanish</option>
        </select>
      </div>
      <div className="flex flex-col justify-center gap-2">
        <label
          htmlFor="temperature"
          className="text-sm text-zinc-700 font-semibold"
        >
          Temperature
        </label>
        <span className=" text-lime-700">{localModelSettings.Temperature}</span>
        <input
          type="range"
          id="temperature"
          value={localModelSettings.Temperature}
          max="1"
          step="0.1" // You can adjust the step as needed
          onChange={handleTemperatureChange}
          className={`w-full h-2 rounded-md cursor-pointer accent-lime-500/25 appearance-none bg-gray-200 text-lime-800`}
        />
        <div className="w-full flex justify-between items-center">
          <span className="text-xs text-lime-700 font-medium">Reserved</span>
          <span className="text-xs text-lime-700 font-medium">Creative</span>
        </div>
      </div>
      <div>
        <h4 className="text-sm text-zinc-800 font-medium">Last trained at</h4>
        <p className="text-zinc-800 font-semibold my-1">
          {convertDateFormat(localModelSettings.LastModifiedDate)}
        </p>
      </div>
      {openBotPersonality && (
        <>
          <div className="w-full h-full fixed top-0 left-0 bg-black opacity-80 z-10"></div>
          <div className="absolute top-10 right-0 z-30 flex justify-center items-center mt-10">
            <BotPersonality setOpenBotPersonality={setOpenBotPersonality} />
          </div>
        </>
      )}
    </div>
  );
};

const Model = () => {
  const { customizeOptions,  chatbotDetails,getModel } =
    useSharedState();

  const ModelSettings = customizeOptions.Model;
  const [localModelSettings, setLocalModelSettings] = useState(ModelSettings);
  const [Loading, setLoading] = useState(false);

  const saveSettings = async () => {
    setLoading(true);
    await getModel(localModelSettings,chatbotDetails.chatbotId);
    setLoading(false);
  };

  useEffect(() => {
    if (chatbotDetails && chatbotDetails.chatbotUI) {
      setLocalModelSettings((prevSettings) => ({
        ...prevSettings,
        model: chatbotDetails.model || prevSettings?.Model?.model,
        Prompt: chatbotDetails.prompt || prevSettings?.Model?.Prompt,
        Language: chatbotDetails.language || prevSettings?.Model?.Language,
        Temperature:
          chatbotDetails.temperature || prevSettings?.Model?.Temperature,
        LastModifiedDate:
          chatbotDetails.lastModifiedDate ||
          prevSettings?.Model?.LastModifiedDate,
      }));
    }
  }, [chatbotDetails]);

  return (
    <div className="w-11/12 lg:w-4/5 flex flex-col gap-16">
      <BoxLayout
        Section="Model"
        handleSaveSettings={saveSettings}
        Loading={Loading}
        dynamicComponent={
          <DynamicComponent
            localModelSettings={localModelSettings}
            setLocalModelSettings={setLocalModelSettings}
          />
        }
      />
    </div>
  );
};

export default Model;
