import React from 'react'
import { useSharedState } from '../../Context/SharedStateContext'

const Leads = () => {
  const {pagesState} = useSharedState()
  if (Object.values(pagesState).some((value) => value === true)) {
    return (
      <div className='min-h-[80vh]'></div>
    )
  }
  return (
    <div>
      Leads
    </div>
  )
}

export default Leads
